import qualitySuites from './qualitySuites';
import qualityChecks from './qualityChecks';
import qualityResults from './qualityResults';
import qualityDashboards from './qualityDashboards';
import assessmentDashboards from './assessmentDashboards';
import qualityProbes from './qualityProbes';
import assessment from './assessment';
import profiling from './profiling';
import anomalies from './anomalies';
import { combineReducers } from 'redux';

export default combineReducers({
  qualitySuites,
  qualityChecks,
  qualityResults,
  qualityDashboards,
  qualityProbes,
  assessment,
  assessmentDashboards,
  profiling,
  anomalies
});
