import ResourceTypes from 'constants/ResourceTypes';
import crudReducers from 'reducers/utils';
import filtersReducer from 'reducers/utils/filters';
import { combineReducers } from 'redux';

const filtersDefault = {};

export default combineReducers({
  filters: filtersReducer({ resource: ResourceTypes.PROFILING_MONITOR, filtersDefault }),
  ...crudReducers({ resource: ResourceTypes.PROFILING_MONITOR, keyProperty: 'uuid' })
});
