import ResourceTypes from 'constants/ResourceTypes';
import crudReducers from 'reducers/utils';
import filtersReducer from 'reducers/utils/filters';
import { combineReducers } from 'redux';

const filtersDefault = {
  search: ''
};

export default combineReducers({
  filters: filtersReducer({ resource: ResourceTypes.PROFILING_METRIC_DEFINITION, filtersDefault }),
  ...crudReducers({ resource: ResourceTypes.PROFILING_METRIC_DEFINITION, keyProperty: 'uuid' })
});
