import React from 'react';
import EngagementCategories from './EngagementCategories';
import ActionWork from '@mui/icons-material/Work';
import { ThumbsUpDown, Assignment, AccountTree } from '@mui/icons-material';
import ActionUsers from '@mui/icons-material/SupervisorAccount';
import ActionProcessings from '@mui/icons-material/Gavel';
import ActionDataCategories from '@mui/icons-material/Extension';
import ActionConsents from '@mui/icons-material/ThumbUp';
import ActionGraph from '@mui/icons-material/Explore';
import ActionDataSources from '@mui/icons-material/Business';
import ActionDataFlows from '@mui/icons-material/SwapCalls';
import ActionQualityKqis from '@mui/icons-material/NetworkCheck';
import ActionQualityProbes from '@mui/icons-material/SettingsInputComponent';
import ActionQualityAssessment from '@mui/icons-material/VerifiedUser';
import CategoryIcon from '@mui/icons-material/Category';
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import ActionSettings from '@mui/icons-material/SettingsTwoTone';
import ActionDataObservability from '@mui/icons-material/Troubleshoot';
import ActionDataProduct from '@mui/icons-material/HexagonTwoTone';

export const EngagementCategoriesIcons = {
  [EngagementCategories.LOGICAL_MODEL]: <ActionDataCategories />,
  [EngagementCategories.PHYSICAL_MODEL]: <ActionDataSources />,
  [EngagementCategories.DATA_FLOW]: <ActionDataFlows />,
  [EngagementCategories.DATA_QUALITY]: <ActionQualityKqis />,
  [EngagementCategories.DATA_QUALITY_PROBES]: <ActionQualityProbes />,
  [EngagementCategories.DATA_QUALITY_ASSESSMENT]: <ActionQualityAssessment />,
  [EngagementCategories.DATA_QUALITY_OBSERVABILITY]: <ActionDataObservability />,
  [EngagementCategories.DATA_ACTOR]: <ActionUsers />,
  [EngagementCategories.STEWARDSHIP]: <Assignment />,
  [EngagementCategories.GRAPH]: <ActionGraph />,
  [EngagementCategories.CLASSIFICATION]: <SortByAlphaIcon />,
  [EngagementCategories.CONSENTS]: <ActionConsents />,
  [EngagementCategories.PROCESSINGS]: <ActionProcessings />,
  [EngagementCategories.TASKS]: <ActionWork />,
  [EngagementCategories.PROPOSALS]: <ThumbsUpDown />,
  [EngagementCategories.CAMPAIGNS]: <Assignment />,
  [EngagementCategories.ISSUES]: <Assignment />,
  [EngagementCategories.SETTINGS]: <ActionSettings />,
  [EngagementCategories.TEMPLATES]: <CategoryIcon />,
  [EngagementCategories.QUERY_PARSER]: <AccountTree />,
  [EngagementCategories.DATA_PRODUCTS]: <ActionDataProduct />,
  [EngagementCategories.GOVERNANCE_POLICIES]: <ActionDataProduct />
};
