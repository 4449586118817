import ResourceTypes from 'constants/ResourceTypes';
import crudReducers from 'reducers/utils';
import filtersReducer from 'reducers/utils/filters';
import { combineReducers } from 'redux';
import _ from 'lodash';
import { FETCH_DATA_PRODUCT_AVAILABLE_DOMAINS } from 'constants/ActionTypes';
const filtersDefault = { search: '' };

const dataProductDomainByName = (state = {}, action) => {
  switch (action.type) {
    case FETCH_DATA_PRODUCT_AVAILABLE_DOMAINS:
      const orderedContent = _(action.payload.content).keyBy('domainName').value();
      return {
        ...state,
        ...orderedContent
      };
    default:
      return state;
  }
};

export default combineReducers({
  dataProductDomainByName,
  filters: filtersReducer({ resource: ResourceTypes.DATA_PRODUCT_DOMAIN, filtersDefault }),
  ...crudReducers({ resource: ResourceTypes.DATA_PRODUCT_DOMAIN, keyProperty: 'uuid' })
});
