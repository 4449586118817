import { Button, Grid, IconButton, Typography, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import NextIcon from '@mui/icons-material/NavigateNext';
import PreviousIcon from '@mui/icons-material/NavigateBefore';
import _ from 'lodash';
import { hasPermissions } from 'components/permissions/permissiosSelectors';
import { SearchableResources, ViewerPermissionsByResourceTypes } from 'constants/ResourceTypes';
import { useSelector } from 'react-redux';
import { ResourceTypesIcons } from 'constants/ResourceTypesIcons';
import { t } from 'i18next';

export const ResourceButtonList = ({ selectedResources, setSelectedResources, pageSize }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const state = useSelector((state) => state);

  const authorizedResourceTypes = SearchableResources.filter((res) =>
    hasPermissions([ViewerPermissionsByResourceTypes[res]])(state)
  );

  const totalPages = authorizedResourceTypes.length / pageSize;
  const pages = _.chunk(authorizedResourceTypes, pageSize);

  return (
    <Grid
      container
      direction='row'
      justifyContent='space-between'
      alignItems='center'
      style={{ paddingTop: '8px' }}>
      <Grid item md={1} sm={1}>
        {currentPage !== 0 && (
          <IconButton
            size='small'
            onClick={() => {
              if (currentPage > 0) setCurrentPage(currentPage - 1);
            }}>
            <PreviousIcon />
          </IconButton>
        )}
      </Grid>
      <Grid item md={10} sm={10}>
        <Grid container spacing={1}>
          {pages[currentPage]?.map((key) => (
            <Grid item md={3} sm={3} key={key}>
              <Button
                variant='outlined'
                style={{
                  width: '100%',
                  height: '100%',
                  fontSize: 'small',
                  color: !selectedResources.includes(key) ? 'grey' : null,
                  padding: '4px'
                }}
                color={selectedResources.includes(key) ? 'primary' : 'grey'}
                onClick={() => {
                  if (selectedResources.includes(key)) {
                    setSelectedResources(_.without(selectedResources, key));
                  } else {
                    setSelectedResources(_.union(selectedResources, [key]));
                  }
                }}>
                {ResourceTypesIcons[key]}
                <Tooltip title={t(`resourceType.${key}`)} enterDelay={300}>
                  <Typography
                    fontWeight='inherit'
                    variant='caption'
                    color='inherit'
                    m='0'
                    pl={1}
                    noWrap>
                    {t(`resourceType.${key}`)}
                  </Typography>
                </Tooltip>
              </Button>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item md={1} sm={1}>
        {currentPage < totalPages - 1 && (
          <IconButton
            size='small'
            onClick={() => {
              if (currentPage < totalPages - 1) setCurrentPage(currentPage + 1);
            }}>
            <NextIcon />
          </IconButton>
        )}
      </Grid>
    </Grid>
  );
};
