import { combineReducers } from 'redux';
import products from './products';
import governancePolicies from './governancePolicies';
import domains from './domains';
import ports from './ports';

export default combineReducers({
  products,
  governancePolicies,
  domains,
  ports
});
