import {
  LOGIN,
  LOGIN_FAILURE,
  LOGOUT,
  FETCH_PROFILE,
  SELECT_TENANT,
  FETCH_ACCOUNT_RESULTING_PERMISSIONS,
  FETCH_ACCOUNT_TEAMS_CONFIGURATION
} from '../constants/ActionTypes';
import LoginApi from '../api/LoginApi';
import { showMessage } from './BehaviourActions';
import { buildApiCallAction } from './ActionUtils';

let api = new LoginApi();

export const login = (username, password) =>
  buildApiCallAction({
    api: api,
    apiFunction: (api) => api.login(username, password),
    actionType: LOGIN,
    onError: (dispatch, getState) => {
      dispatch({ type: LOGIN_FAILURE });
    }
  });

export const logout = () =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.logout(),
    actionType: LOGOUT
  });

export const changePassword = (request) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.putPassword(request),
    actionType: 'CHANGE_PASSWORD',
    onSuccess: (dispatch, getState) => {
      showMessage('Password changed')(dispatch, getState);
    }
  });

export const resetPasswordStart = (request) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.resetPasswordStart(request),
    actionType: 'RESET_PASSWORD_START',
    onSuccess: (dispatch, getState) => {
      showMessage('Password reset completed')(dispatch, getState);
    }
  });

export const resetPasswordFinish = (request) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.resetPasswordFinish(request),
    actionType: 'RESET_PASSWORD_FINISH',
    onSuccess: (dispatch, getState) => {
      showMessage('Password reset completed')(dispatch, getState);
    }
  });

export const fetchProfile = () =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getAccount(),
    actionType: FETCH_PROFILE
  });

export const fetchAccountResultingPermissions = () =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getAccountResultingPermissions(),
    actionType: FETCH_ACCOUNT_RESULTING_PERMISSIONS
  });

export const fetchTeamConfiguration = () =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getTeamsConfiguration(),
    actionType: FETCH_ACCOUNT_TEAMS_CONFIGURATION
  });

export const selectTenant = (tenant) => (dispatch, getState) => {
  dispatch({ type: SELECT_TENANT, payload: tenant });
  dispatch(fetchAccountResultingPermissions());
};

export const fetchAuthSettings = (params) =>
  buildApiCallAction({
    api,
    apiFunction: (api) => api.getAuthSettings(params),
    actionType: 'FETCH_AUTH_SETTINGS'
  });

export const verifyOIDCToken = ({ username, provider, idToken, history }) =>
  buildApiCallAction({
    api: api,
    apiFunction: (api) => api.verifyOIDCIdToken(provider, { idToken, username }),
    actionType: 'LOGIN',
    onError: (dispatch, getState) => {
      dispatch({ type: LOGIN_FAILURE });
      history.push('/');
    }
  });

export const verifyOIDCCode = ({ provider, code, username, history }) =>
  buildApiCallAction({
    api: api,
    apiFunction: (api) => api.verifyOIDCCode(provider, { code, username }),
    actionType: 'LOGIN',
    onError: (dispatch, getState) => {
      dispatch({ type: LOGIN_FAILURE });
      history.push('/');
    }
  });
