import { combineReducers } from 'redux';
import crudReducers from 'reducers/utils';
import filtersReducer from 'reducers/utils/filters';
import ResourceTypes from 'constants/ResourceTypes';

import {
  FETCH_QUERY_STATEMENT_FULL_LINEAGE,
  FETCH_QUERY_STATEMENT_ENDING_NODES,
  FETCH_DATA_FLOWS_INVOLVED_STATEMENTS,
  CREATE_DATA_FLOW_PREVIEWS,
  FETCH_DATA_FLOW_PREVIEWS_PAGE
} from 'constants/ActionTypes';

const filtersDefault = {
  search: '',
  statuses: [],
  creationDateGt: null,
  creationDateLt: null
};

function fullLineage(state = {}, action) {
  switch (action.type) {
    case FETCH_QUERY_STATEMENT_FULL_LINEAGE:
      return {
        ...state,
        [action.payload.queryStatementUuid]: action.payload
      };
    default:
      return state;
  }
}

function dataflowsinvolved(state = {}, action) {
  switch (action.type) {
    case FETCH_DATA_FLOWS_INVOLVED_STATEMENTS:
      return action.payload;
    default:
      return state;
  }
}

function endingNodes(state = {}, action) {
  switch (action.type) {
    case FETCH_QUERY_STATEMENT_ENDING_NODES:
      return {
        ...state,
        [action.payload.queryStatementUuid]: action.payload.endingProjectionNodes
      };
    default:
      return state;
  }
}

function dataflowpreviews(state = {}, action) {
  switch (action.type) {
    case CREATE_DATA_FLOW_PREVIEWS:
    case FETCH_DATA_FLOW_PREVIEWS_PAGE:
      return { ...state, [action.payload.queryStatementUuid]: action.payload.content };
    default:
      return state;
  }
}

export default combineReducers({
  ...crudReducers({ resource: ResourceTypes.QUERY_STATEMENT, keyProperty: 'uuid' }),
  filters: filtersReducer({
    resource: ResourceTypes.QUERY_STATEMENT,
    filtersDefault
  }),
  dataflowpreviews,
  dataflowsinvolved,
  fullLineage,
  endingNodes
});
