import React, { useState } from 'react';
import { fetchAuthSettings, verifyOIDCToken, verifyOIDCCode } from 'actions/LoginActions';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import _ from 'lodash';
import { LAST_OIDC_USERNAME_KEY } from '../login/OIDCImplicitFlowButton';

export default function ExtractAccessToken() {
  let dispatch = useDispatch();
  let [started, setStarted] = useState(false);
  let history = useHistory();
  let params = useParams();
  let provider = params.provider;
  let username = localStorage.getItem(LAST_OIDC_USERNAME_KEY);
  React.useEffect(() => {
    if (started) {
      return;
    }
    setStarted(true);
    dispatch(fetchAuthSettings({ username })).then((data) => {
      let setting = _.find(data.settings, { provider });
      let tokenType = setting.tokenType;
      if (tokenType === 'code') {
        // OAuth2 Authorization code flow
        var code = new URLSearchParams(window.location.search).get(tokenType);
        dispatch(verifyOIDCCode({ provider, code, username, history })).then(() =>
          history.push('/')
        ); //important to avoid verify loop
      } else {
        // OAuth2 Implicit flow
        var idToken = new URLSearchParams(window.location.hash.substr(1)).get(tokenType);
        dispatch(verifyOIDCToken({ provider, idToken, username, history })).then(() =>
          history.push('/')
        ); //important to avoid verify loop
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return null;
}
