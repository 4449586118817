import { FETCH_PROFILING_METRIC_FORECASTS } from 'constants/ActionTypes';
import _ from 'lodash';
import { combineReducers } from 'redux';

const metricRecordsByMetricDefinition = (state = {}, action) => {
  switch (action.type) {
    case FETCH_PROFILING_METRIC_FORECASTS:
      const decompressedRecords = _(action.payload?.metricForecastsByMetricDefinition)
        .entries()
        .map((entry) => [
          [entry[0]],
          entry[1]?.rows?.map((row) => _.zipObject(entry[1]?.columns, row))
        ])
        .fromPairs()
        .value();
      return { ...state, ...decompressedRecords };
    default:
      return state;
  }
};

export default combineReducers({ metricRecordsByMetricDefinition });
