import { showMessage } from 'actions/BehaviourActions';
import { verifyOIDCToken } from 'actions/LoginActions';
import React from 'react';
import { PublicClientApplication } from '@azure/msal-browser';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { Button, SvgIcon, Typography } from '@mui/material';

function MSAzureLoginWrapper({ setting, username }) {
  let dispatch = useDispatch();
  let history = useHistory();
  let location = useLocation();
  const msalInstance = new PublicClientApplication(msalConfig(setting));
  const handleLogin = async () => {
    try {
      await msalInstance.initialize();
      const { idToken } = await msalInstance.loginPopup(loginRequest);
      await dispatch(verifyOIDCToken({ provider: 'msazure', idToken, username, history }));
      history.push(location.state?.from || '/');
    } catch (e) {
      console.error(e);
      dispatch(showMessage(e?.errorMessage));
    }
  };
  return (
    <div style={{ margin: 4 }}>
      <Button
        style={{ width: 214, height: 42, backgroundColor: '#FFFFFF' }}
        variant='contained'
        color='primary'
        onClick={handleLogin}
        startIcon={
          <SvgIcon width={21} height={21} viewBox='0 0 21 21'>
            <title>MS-SymbolLockup</title>
            <rect x='1' y='1' width='9' height='9' fill='#f25022' />
            <rect x='1' y='11' width='9' height='9' fill='#00a4ef' />
            <rect x='11' y='1' width='9' height='9' fill='#7fba00' />
            <rect x='11' y='11' width='9' height='9' fill='#ffb900' />
          </SvgIcon>
        }>
        <Typography
          style={{
            fontSize: 12,
            fontFamily: 'Segoe UI Regular',
            fontWeight: 600,
            textTransform: 'none',
            color: 'black'
          }}>
          Sign in with Microsoft
        </Typography>
      </Button>
    </div>
  );
}

export const msalConfig = (setting) => ({
  auth: {
    clientId: setting.clientId,
    authority: setting.authority, // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri: setting.redirectUri
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
  }
});

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ['openid', 'profile', 'email']
};

export default MSAzureLoginWrapper;
