import React from 'react';
import '../login/LoginPage.css';
import { Link } from 'react-router-dom';
import { Card, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ApiReferenceReact } from '@scalar/api-reference-react';
import './docs.css';

function ApiDocsPage() {
  return (
    <>
      <IconButton
        style={{ position: 'absolute', top: '10px', left: '10px' }}
        component={Link}
        to='/login'
        size='large'>
        <ArrowBackIcon></ArrowBackIcon>
      </IconButton>
      <Card
        style={{
          position: 'absolute',
          background: 'white',
          left: '64px',
          right: '0px',
          bottom: '0px',
          top: '0px',
          overflow: 'auto'
        }}>
        <ApiReferenceReact
          configuration={{
            spec: {
              url: '/docs/v3/api-docs'
            },
            withDefaultFonts: true,
            hideModels: true,
            showSidebar: true,
            darkMode: true
          }}
        />
        {/*
                <SwaggerUI url='/docs/v3/api-docs' />

        <RedocStandalone
          specUrl='/docs/v3/api-docs'
          options={{
            nativeScrollbars: true,
            theme: { colors: { primary: { main: '#00aeca' } } }
          }}></RedocStandalone>*/}
      </Card>
    </>
  );
}

export default ApiDocsPage;
