import ResourceTypes from 'constants/ResourceTypes';
import crudReducers from 'reducers/utils';
import filtersReducer from 'reducers/utils/filters';
import { combineReducers } from 'redux';

const filtersDefault = { search: '' };

export default combineReducers({
  filters: filtersReducer({ resource: ResourceTypes.LOGICAL_PREDICATE, filtersDefault }),
  ...crudReducers({ resource: ResourceTypes.LOGICAL_PREDICATE, keyProperty: 'uuid' })
});
