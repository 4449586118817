import { combineReducers } from 'redux';
import crudReducers from './utils';
import filtersReducer from 'reducers/utils/filters';
import ResourceTypes from 'constants/ResourceTypes';
import { FETCH_DATACATEGORY_ATTRIBUTES_PAGE } from '../constants/ActionTypes';

const filtersDefault = {
  dataClassIsNull: false,
  dataClass: [],
  clearance: null,
  teamsCodes: [],
  dataCategoriesUuid: [],
  search: '',
  nullTeamCode: undefined,
  systems: []
};

const attributes = (state = {}, action) => {
  switch (action.type) {
    case FETCH_DATACATEGORY_ATTRIBUTES_PAGE:
      return {
        ...state,
        currentPage: action.payload
      };
    default:
      return state;
  }
};

export default combineReducers({
  ...crudReducers({ resource: ResourceTypes.DATA_CATEGORY, keyProperty: 'uuid' }),
  filters: filtersReducer({
    resource: ResourceTypes.DATA_CATEGORY,
    filtersDefault: filtersDefault
  }),
  attributes
});
