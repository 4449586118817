import monitors from './monitors';
import metricDefinitions from './metricdefinitions';
import metricRecords from './metricrecords';
import metricForecasts from './metricforecasts';
import { combineReducers } from 'redux';

export default combineReducers({
  monitors,
  metricDefinitions,
  metricRecords,
  metricForecasts
});
