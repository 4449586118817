import React from 'react';
import { Dialog, DialogContent } from '@mui/material';
import GlobalSearch from './GlobalSearch';
import withError from 'components/errors/withError';

function GlobalSearchModal({ open, onClose }) {
  return (
    <GlobalSearch
      onExit={onClose}
      WrapperComponent={(props) => <DialogComponent open={open} onClose={onClose} {...props} />}
    />
  );
}

const DialogComponent = ({ open, onClose, ...props }) => (
  <Dialog
    open={open}
    onClose={onClose}
    fullWidth
    maxWidth='md'
    PaperProps={{
      style: {
        backgroundColor: 'transparent',
        boxShadow: 'none'
      }
    }}>
    <DialogContent>{props.children}</DialogContent>
  </Dialog>
);

export default withError({ componentDetails: 'GlobalSearchModal' })(GlobalSearchModal);
