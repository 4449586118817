import React from 'react';
import ResourceTypes from 'constants/ResourceTypes';
import LogicalNamespaceIcon from '@mui/icons-material/Hub';
import DataCategoryIcon from '@mui/icons-material/ExtensionOutlined';
import LogicalFieldIcon from '@mui/icons-material/ListAlt';
import LogicalRelationIcon from '@mui/icons-material/Link';
import SystemIcon from '@mui/icons-material/StorageRounded';
import PhysicalEntityIcon from '@mui/icons-material/TableChartOutlined';
import PhysicalFieldIcon from '@mui/icons-material/NoteSharp';
import ActionProcessing from '@mui/icons-material/GavelTwoTone';
import ActionUsers from '@mui/icons-material/SupervisorAccountTwoTone';
import ActionWork from '@mui/icons-material/WorkOutlineTwoTone';
import ActionQualitySuite from '@mui/icons-material/NetworkCheckTwoTone';
import ActionQualityCheck from '@mui/icons-material/VerifiedUser';
import ActionQueryBucket from '@mui/icons-material/DynamicFeedOutlined';
import ActionQualityProbesDefinition from '@mui/icons-material/SettingsInputComponent';
import ActionQualityProbesProject from '@mui/icons-material/Ballot';
import ActionDataProduct from '@mui/icons-material/HexagonTwoTone';
import ActionProfilingMonitor from '@mui/icons-material/MonitorHeartOutlined';
import ActionProfilingMetricDefinition from '@mui/icons-material/Timeline';
import ActionMetricIncident from '@mui/icons-material/AppsOutage';
import ActionMetricAnomaly from '@mui/icons-material/NearbyError';
import ActionGovernancePolicySuite from '@mui/icons-material/FolderOpen';
import ActionGovernancePolicy from '@mui/icons-material/Policy';
import ActionLogicalPredicate from '@mui/icons-material/ArticleOutlined';

export const UnstyledResourceTypesIcons = {
  [ResourceTypes.LOGICAL_PREDICATE]: (props) => <ActionLogicalPredicate {...props} />,
  [ResourceTypes.LOGICAL_NAMESPACE]: (props) => <LogicalNamespaceIcon {...props} />,
  [ResourceTypes.DATA_CATEGORY]: (props) => <DataCategoryIcon {...props} />,
  [ResourceTypes.LOGICAL_FIELD]: (props) => <LogicalFieldIcon {...props} />,
  [ResourceTypes.LOGICAL_RELATION]: (props) => <LogicalRelationIcon {...props} />,
  [ResourceTypes.SYSTEM]: (props) => <SystemIcon {...props} />,
  [ResourceTypes.PHYSICAL_ENTITY]: (props) => <PhysicalEntityIcon {...props} />,
  [ResourceTypes.PHYSICAL_FIELD]: (props) => <PhysicalFieldIcon {...props} />,
  [ResourceTypes.PROCESSING]: (props) => <ActionProcessing {...props} />,
  [ResourceTypes.DATA_ACTOR]: (props) => <ActionUsers {...props} />,
  [ResourceTypes.TASK]: (props) => <ActionWork {...props} />,
  [ResourceTypes.QUALITY_SUITE]: (props) => <ActionQualitySuite {...props} />,
  [ResourceTypes.QUALITY_CHECK]: (props) => <ActionQualityCheck {...props} />,
  [ResourceTypes.QUERY_BUCKET]: (props) => <ActionQueryBucket {...props} />,
  [ResourceTypes.QUALITY_PROBE_DEFINITION]: (props) => <ActionQualityProbesDefinition {...props} />,
  [ResourceTypes.QUALITY_PROBE_PROJECT]: (props) => <ActionQualityProbesProject {...props} />,
  [ResourceTypes.DATA_PRODUCT]: (props) => <ActionDataProduct {...props} />,
  [ResourceTypes.DATA_PRODUCT_DOMAIN]: (props) => <ActionDataProduct {...props} />,
  [ResourceTypes.PROFILING_MONITOR]: (props) => <ActionProfilingMonitor {...props} />,
  [ResourceTypes.PROFILING_METRIC_DEFINITION]: (props) => (
    <ActionProfilingMetricDefinition {...props} />
  ),
  [ResourceTypes.PROFILING_METRIC_INCIDENT]: (props) => <ActionMetricIncident {...props} />,
  [ResourceTypes.PROFILING_METRIC_ANOMALY]: (props) => <ActionMetricAnomaly {...props} />,
  [ResourceTypes.GOVERNANCE_POLICY_SUITE]: (props) => <ActionGovernancePolicySuite {...props} />,
  [ResourceTypes.GOVERNANCE_POLICY]: (props) => <ActionGovernancePolicy {...props} />
};

export const ResourceTypesColors = {
  // [ResourceTypes.LOGICAL_NAMESPACE]: ,
  [ResourceTypes.DATA_CATEGORY]: '#4248f4',
  [ResourceTypes.LOGICAL_FIELD]: '#4a9cad',
  // [ResourceTypes.LOGICAL_RELATION]: ,
  [ResourceTypes.SYSTEM]: '6b6b6b',
  [ResourceTypes.PHYSICAL_ENTITY]: '#383838',
  [ResourceTypes.PHYSICAL_FIELD]: '#4a3c05',
  [ResourceTypes.PROCESSING]: '#ffbc38',
  [ResourceTypes.DATA_ACTOR]: '#FF00CC',
  [ResourceTypes.TASK]: '#ff8e38',
  [ResourceTypes.QUALITY_SUITE]: '#acd079',
  [ResourceTypes.QUALITY_CHECK]: '#7ac215',
  // [ResourceTypes.QUERY_BUCKET]: ,
  [ResourceTypes.QUALITY_PROBE_DEFINITION]: '#4884ff',
  [ResourceTypes.QUALITY_PROBE_PROJECT]: '#91b6ff',
  [ResourceTypes.DATA_PRODUCT]: '6b6b6b'
  // [ResourceTypes.DATA_PRODUCT_DOMAIN]: ,
  // [ResourceTypes.PROFILING_MONITOR]: ,
  // [ResourceTypes.PROFILING_METRIC_DEFINITION]: ,
  // [ResourceTypes.PROFILING_METRIC_INCIDENT]: ,
  // [ResourceTypes.PROFILING_METRIC_ANOMALY]: ,
  // [ResourceTypes.GOVERNANCE_POLICY]:  ,
  // [ResourceTypes.GOVERNANCE_POLICY_SUITE]:
};

export const ResourceTypesIcons = Object.assign(
  {},
  ...Object.entries(UnstyledResourceTypesIcons).map(([resourceType, resourceIcon]) => {
    return {
      [resourceType]: resourceIcon({
        style: { color: ResourceTypesColors[resourceType] }
      })
    };
  })
);

export const getResourceNameAndIcon = (
  resourceType,
  resourceName,
  { rootStyle = {}, textStyle = {} } = {},
  unstyled = false
) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      ...rootStyle
    }}>
    {unstyled
      ? UnstyledResourceTypesIcons[resourceType](rootStyle)
      : ResourceTypesIcons[resourceType]}
    <div
      style={{
        paddingLeft: '4px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        ...textStyle
      }}
      title={resourceName}>
      {resourceName}
    </div>
  </div>
);
