import { combineReducers } from 'redux';
import { SELECT_TENANT, FETCH_PROFILE } from '../../constants/ActionTypes';
import _ from 'lodash';

const selectedTenant = (state = loadSelectedTenant(), action) => {
  switch (action.type) {
    case SELECT_TENANT:
      saveSelectedTenant(action.payload);
      return action.payload;
    case FETCH_PROFILE:
      return state || action.payload.tenant;
    default:
      return state;
  }
};

const content = (state = {}, action) => {
  switch (action.type) {
    case FETCH_PROFILE:
      return {
        ...state,
        ..._(action.payload.associatedTenants).keyBy('uuid').value()
      };
    default:
      return state;
  }
};

function loadSelectedTenant() {
  try {
    const selectedTenantString = localStorage.getItem('selectedTenant');
    return JSON.parse(selectedTenantString);
  } catch (e) {
    return null;
  }
}

function saveSelectedTenant(selectedTenant) {
  if (!selectedTenant) {
    localStorage.removeItem('selectedTenant');
  }
  try {
    localStorage.setItem('selectedTenant', JSON.stringify(selectedTenant));
  } catch (e) {}
}

export const selectCurrentTenant = (state) =>
  state.settings.tenants.selectedTenant || state.settings.profile.tenant;

export default combineReducers({ content, selectedTenant });
