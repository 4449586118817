import { combineReducers } from 'redux';
import {
  FETCH_DOCUMENT_CONTENT,
  CREATE_DOCUMENT_CONTENT,
  MODIFY_DOCUMENT_CONTENT,
  FETCH_DOCUMENTS_CONTENTS_PAGE,
  DELETE_DOCUMENT_CONTENT
} from 'constants/ActionTypes';
import crudReducers from '../utils';

const contentByResource = (state = {}, action) => {
  switch (action.type) {
    case FETCH_DOCUMENTS_CONTENTS_PAGE:
      return {
        ...state,
        ...action.payload.content.reduce((dict, current) => ({ ...dict, [`${current.resourceType}:${current.resourceIdentifier}`]: current}), {})
      }
    case FETCH_DOCUMENT_CONTENT:
    case CREATE_DOCUMENT_CONTENT:
    case MODIFY_DOCUMENT_CONTENT:
      return {
        ...state,
        [`${action.payload.resourceType}:${action.payload.resourceIdentifier}`]: action.payload
      };
    case DELETE_DOCUMENT_CONTENT:
      delete state[`${action.payload.resourceType}:${action.payload.resourceIdentifier}`];
      return state;
    default:
      return state;
  }
};

export default combineReducers({
  ...crudReducers({ resource: 'DOCUMENT_CONTENT', keyProperty: 'sequenceId' }),
  contentByResource
});

export const selectDocumentation = (resourceType, resourceIdentifier) => (state) =>
  state.social.documents.contentByResource[`${resourceType}:${resourceIdentifier}`];
