import ResourceTypes from 'constants/ResourceTypes';
import crudReducers from 'reducers/utils';
import { combineReducers } from 'redux';
import { FETCH_POLICIES_AND_ADOPTIONS_LIST } from 'constants/ActionTypes';

export const CURRENT_PAGE_INITIAL_STATE = {
  content: [],
  number: 0,
  totalPages: 0,
  totalElements: 0,
  size: 20
};
const policiesAndAdoptions = (state = CURRENT_PAGE_INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_POLICIES_AND_ADOPTIONS_LIST:
      return {
        ...action.payload
      };
    default:
      return state;
  }
};
export default combineReducers({
  ...crudReducers({ resource: ResourceTypes.GOVERNANCE_POLICY_ADOPTION, keyProperty: 'uuid' }),
  policiesAndAdoptions
});
