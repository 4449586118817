import {
  CLEAR_IMPACT_ANALYSIS,
  CLEAR_IMPACT_ANALYSIS_FILTERS,
  EXECUTE_IMPACT_ANALYSIS,
  SET_IMPACT_ANALYSIS_FILTERS
} from 'constants/ActionTypes';
import { combineReducers } from 'redux';

const results = (state = {}, action) => {
  switch (action.type) {
    case EXECUTE_IMPACT_ANALYSIS:
      return action.payload;
    case CLEAR_IMPACT_ANALYSIS:
      return {};
    default:
      return state;
  }
};

const defaultFilters = {
  maxDepth: 10,
  columnLevel: false,
  dataCategories: [],
  logicalFields: [],
  physicalEntities: [],
  physicalFields: []
};

const filters = (state = defaultFilters, action) => {
  switch (action.type) {
    case SET_IMPACT_ANALYSIS_FILTERS:
      return action.payload;
    case CLEAR_IMPACT_ANALYSIS_FILTERS:
      return defaultFilters;
    default:
      return state;
  }
};

export default combineReducers({
  results,
  filters
});
