import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import './MaterialTitlePanel.css';
import { muiThemeV1 as contentTheme } from './ContentTheme';
import { ThemeProvider } from '@mui/material/styles';
import logo from '../../images/logo_symbol.png';
import NotificationIconButton from '../social/notifications/NotificationIconButton';
import { Grid } from '@mui/material';
import { NavigationActions } from 'pages/sidebar/Navigation';
import GlobalSearchButton from 'pages/home/homesearch/GlobalSearchButton';
import TenantSwitcherButton from 'pages/settings/tenants/commons/TenantSwitcherButton';

export const toolbarActionsId = 'toolbar-actions';

const MaterialTitlePanel = (props) => {
  const contentHeader = (
    <Grid container alignItems='center'>
      {!props.sideBarDocked && (
        <Grid item>
          <IconButton onClick={props.toggleOpen} disableTouchRipple={true} size='large'>
            <img id='material-title-menu-image' src={logo} alt='Logo' />
          </IconButton>
        </Grid>
      )}
      {/* Elements are injected in breadcrumbs via Portals*/}
      <Grid item style={{ flexGrow: 1 }}>
        <NavigationActions />
      </Grid>
      <Grid item>
        <TenantSwitcherButton
          sx={{
            position: 'absolute',
            top: 0,
            left: '50%',
            right: '50%',
            textWrap: 'nowrap',
            transform: 'translate(-50%,0%)'
          }}
        />
      </Grid>
      {/* Elements are injected in toolbar-actions via Portals*/}
      <Grid item style={{ flexGrow: 1 }}>
        <div style={{ float: 'right', flexWrap: 'wrap' }} id={toolbarActionsId} />
      </Grid>
      <Grid item>
        <GlobalSearchButton />
        <NotificationIconButton></NotificationIconButton>
      </Grid>
    </Grid>
  );
  return (
    <ThemeProvider theme={contentTheme}>
      <div id='material-title-root'>
        <div id='material-title-header'>{contentHeader}</div>
        {props.children}
      </div>
    </ThemeProvider>
  );
};

MaterialTitlePanel.propTypes = {
  sideBarOpen: PropTypes.bool.isRequired,
  sideBarDocked: PropTypes.bool.isRequired,
  children: PropTypes.object.isRequired
};

export default MaterialTitlePanel;
