import React, { useEffect, useState } from 'react';
import './LoginPage.css';
import { useDispatch } from 'react-redux';
import { fetchAuthSettings } from '../../../actions/LoginActions';
import logo_oriz from '../../../images/logo_oriz_white.png';
import { Link } from 'react-router-dom';
import { Button, Checkbox, FormControlLabel, TextField, Typography } from '@mui/material';
import { CircularProgress } from '@mui/material';
import GoogleLoginWrapper from './GoogleLoginWrapper';
import MSAzureLoginWrapper from './MSAzureLoginWrapper';
import OIDCImplicitFlowButton from './OIDCImplicitFlowButton';
import _ from 'lodash';
import BlindataSignInButton, { blindataLoginButtonStyle } from './BlindataSignInButton';

function LoginPage() {
  let [username, setUsername] = useState(localStorage.getItem('login.username'));
  let content = null;
  if (username) {
    content = (
      <LoginSettings
        username={username}
        onCancel={() => {
          setUsername(null);
          localStorage.removeItem('login.username');
        }}
      />
    );
  } else {
    content = (
      <LoginAskUsername
        onUsernameSelected={(username, rememberMe) => {
          setUsername(username);
          if (rememberMe) {
            localStorage.setItem('login.username', username);
          }
        }}
      />
    );
  }
  return (
    <form id='login-div'>
      <img id='login-logo' src={logo_oriz} alt='Logo' />
      {content}
    </form>
  );
}

function LoginAskUsername({ onUsernameSelected }) {
  let [username, setUsername] = useState(null);
  let [rememberMe, setRememberMe] = useState(false);
  return (
    <>
      <TextField
        variant='standard'
        id='username-textfield'
        placeholder=''
        label='Username'
        InputLabelProps={{
          shrink: true
        }}
        onChange={(event) => setUsername(event.target.value)}
        margin='normal'
      />
      <FormControlLabel
        label='Remember me'
        sx={{ color: 'white' }}
        control={
          <Checkbox
            size='small'
            checked={rememberMe}
            onChange={(event) => setRememberMe(event.target.checked)}
          />
        }
      />

      <br />
      <Button
        id='login-button'
        variant='outlined'
        children='Continue'
        style={blindataLoginButtonStyle}
        disabled={!username}
        onClick={(event) => onUsernameSelected(username, rememberMe)}
      />

      <input type='submit' hidden onClick={(event) => onUsernameSelected(username, rememberMe)} />
    </>
  );
}

function LoginSettings({ username, onCancel }) {
  let dispatch = useDispatch();
  let [authSettingsResponse, setAuthSettingsResponse] = useState(null);

  useEffect(
    function () {
      if (!authSettingsResponse) {
        dispatch(fetchAuthSettings({ username })).then(setAuthSettingsResponse);
      }
    },
    [dispatch, username, setAuthSettingsResponse, authSettingsResponse]
  );
  if (!authSettingsResponse) {
    return <CircularProgress></CircularProgress>;
  }
  return (
    <>
      <BlindataSignInButton
        username={username}
        setting={_.find(authSettingsResponse.settings, { provider: 'blindata' })}
      />
      {authSettingsResponse?.settings &&
        authSettingsResponse.settings.map((setting) => renderSSOBUTTON(setting, username))}
      <Button
        variant='outlined'
        children='Back'
        style={blindataLoginButtonStyle}
        onClick={() => onCancel()}
      />
      <Typography
        style={{ marginTop: 16 }}
        id='forgot-link'
        variant='caption'
        component={Link}
        to='/reset/init'>
        Forgot password?
      </Typography>
    </>
  );
}

function renderSSOBUTTON(setting, username) {
  if (setting.provider === 'google') {
    return <GoogleLoginWrapper key='google' setting={setting} username={username} />;
  } else if (setting.provider === 'msazure') {
    return <MSAzureLoginWrapper key='msazure' setting={setting} username={username} />;
  }
  return <OIDCImplicitFlowButton key={setting.provider} setting={setting} username={username} />;
}

export default LoginPage;
