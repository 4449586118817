import React from 'react';
import '../login/LoginPage.css';
import { Link } from 'react-router-dom';
import { Card, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

function TermsAndConditionsPage() {
  return (
    <>
      <IconButton
        style={{ position: 'absolute', top: '10px', left: '10px' }}
        component={Link}
        to='/login'
        size='large'>
        <ArrowBackIcon></ArrowBackIcon>
      </IconButton>
      <Card
        style={{
          position: 'absolute',
          top: '10%',
          left: '5%',
          width: '90%',
          height: '80%',
          padding: '30px',
          overflow: 'auto'
        }}>
        <form>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <h1>General terms and conditions of the software license service SAAS </h1>
          </div>
          <p style={{ color: 'white' }}>
            With these general terms and conditions (<b>“General Terms”</b> or, more generally, the
            <b>“Contract”</b>) the terms and conditions are defined in accordance with which
            Blindata will make available to the subject, from time to time identified in the Order (
            <b>“Customer”</b>), (singularly the <b>“Party”</b> and, collectively, the
            <b>“Parties”</b>) the right to use the platform in “Software as a Service” mode (“SAAS”)
            for the management of “Data Management” processes, in compliance with privacy
            regulations (<b>“Platform”</b>), as well as the additional features or services
            connected (collectively, the
            <b>“Service”</b>), depending on the Software Module indicated in the Order.<br></br>
            <br></br> The Contract consists of the Order and its respective attachments, of which
            these General Terms are part. Any derogations from this Contract will be valid and
            effective only if contained in documents accepted and formalized in writing by both
            Parties.
          </p>
          <h2 style={{ color: 'white' }}>1 Conclusion of the contract</h2>
          <p style={{ color: 'white' }}>
            <ul>
              <ll>
                1.1) The Contract is considered validly concluded between the Parties with the
                receipt of the acceptance of the Order relating to the Service, duly signed by the
                Customer. The conclusion of the Contract implies the automatic full acceptance of
                these General Terms by the Customer.
              </ll>
              <br></br>
              <br></br>
              <ll>
                1.2) The Customer has the right to activate different types of Software Modules
                related to the Service. Blindata will proceed with the activation of the Service
                within 10 days from the signing of the Contract, giving notice to the Customer.
              </ll>
            </ul>
          </p>
          <h2 style={{ color: 'white' }}>2 Fees and payments</h2>
          <p style={{ color: 'white' }}>
            <ul>
              <ll>
                2.1) The Customer is required to pay the fee, according to the methods and timing
                indicated in the order form (“Fee”). Blindata reserves the right to make changes to
                the Fee, provided that the new fee will be applied after the expiration of the
                Contract, and without prejudice to the Customer's right to withdraw from the
                Contract within 30 (thirty) days from receipt of Blindata's communication of change.
              </ll>
              <br></br>
              <br></br>
              <ll>
                2.2) The Fee varies and is calculated based on the Software Module/s chosen and the
                Parameters applied. The Fee does not include any assistance and start-up costs. Any
                additional service will therefore be subject to a separate agreement.
              </ll>
              <br></br>
              <br></br>
              <ll>
                2.3) Blindata reserves the right to suspend the Service even without notice in case
                of non-payment or late payment of the Fee. After 30 (thirty) days have elapsed
                without effect from the suspension of the Service, the Contract will be terminated
                by law for breach, without prejudice to Blindata’s right to retain the Fee paid up
                to that point as a penalty, without prejudice to compensation for further damage.
              </ll>
            </ul>
          </p>
          <h2 style={{ color: 'white' }}>3 Use of the service and platform</h2>
          <p style={{ color: 'white' }}>
            <ul>
              <ll>
                3.1) The Customer is required to pay the fee, according to the methods and timing
                indicated in the order form (“Fee”). Blindata reserves the right to make changes to
                the Fee, provided that the new fee will be applied after the expiration of the
                Contract, and without prejudice to the Customer’s right to withdraw from the
                Contract within 30 (thirty) days from receipt of Blindata’s communication of change.
              </ll>
              <br></br> <br></br>
              <ll>
                3.2)The Fee varies and is calculated based on the Software Module/s chosen and the
                Parameters applied. The Fee does not include any assistance and start-up costs. Any
                additional service will therefore be subject to a separate agreement.
              </ll>
            </ul>
            <p>
              The Parameters may be those defined below:<br></br>
            </p>
            <ul>
              <ll>
                <b>API call</b>: indicates and defines the calls made to register events, typically
                related to consent;
              </ll>
              <br></br> <br></br>
              <ll>
                <b>Tenant</b>: indicates and defines the company or legal entity for which
                processing records are defined. Typically it plays the role of Data Controller or
                Data Processor;
              </ll>
              <br></br> <br></br>
              <ll>
                <b>Users</b>: indicates and defines the number of nominal users activated, once
                credentials for access to the Platform have been created;
              </ll>
              <br></br> <br></br>
              <ll>
                <b>Processing</b>: indicates and defines data processing, whether it concerns
                personal data or data of different nature and content;
              </ll>
              <br></br> <br></br>
              <ll>
                <b>System</b>: indicates and defines applications, databases, folders on “File
                System” mapped within the Platform.
              </ll>
              <br></br> <br></br>
              <ll>
                <b>Tables</b>: indicates and defines the number of tables and views subject to
                application of rules defined in Data Classification module
              </ll>
              <br></br>
              <br></br>
              <ll>
                <b>Quality Check</b>: indicates and defines the number of quality checks created
                within the Platform.
              </ll>
            </ul>
          </p>
          <ul>
            <ll>
              3.3) The Customer undertakes to keep Blindata harmless from any claim that may be made
              against it for any reason or in any way connected to the unsuitability of the
              Customer’s hardware and/or network and/or software systems capable of allowing the
              correct use of the Service.
            </ll>
            <br></br> <br></br>
            <ll>
              3.4) The Customer undertakes to use the Service in accordance with this Contract and
              the law. It is expressly forbidden for the Customer to: <br></br> <br></br>
              <ul>
                <ll>
                  - Decode, decompile or disassemble the Platform or perform reverse engineering on
                  it;
                </ll>
                <br></br> <br></br>
                <ll>
                  - Generate in any way and by any means, through customization operations, the
                  conviction that the Platform is its own property and / or creation;
                </ll>
                <br></br> <br></br>
                <ll>
                  - Publish the Platform and/or parts of it, to allow duplication or any other use
                  by others;
                </ll>
                <br></br> <br></br>
                <ll>
                  - Allow access to information collected, stored and processed through the Service
                  to other users in ways other than those expressly provided for in this Contract.
                </ll>
                <br></br>
              </ul>
            </ll>
            <br></br> <br></br>
            <ll>
              3.5) The Customer agrees to provide Blindata, directly and/or through its delegate,
              with the information necessary to verify the actual conditions of use of the Service
              and their compliance with the Contract; such information may also be acquired by
              Blindata and/or its representative through telematic procedure.
            </ll>
            <br></br> <br></br>
            <ll>
              3.6) The Customer acknowledges and accepts that the use of the Platform does not
              guarantee, by itself, compliance with EU Regulation 2016/679 of 27 April 2016 and
              italian Legislative Decree no. 196/2003 “Code regarding the protection of personal
              data”, as amended by italian Legislative Decree no. 101/2018, therefore remaining with
              the Customer the responsibility for compliance with such regulations, also by adopting
              appropriate internal processes.
            </ll>
          </ul>
          <h2 style={{ color: 'white' }}>4 Temporary suspension or interruption of the service</h2>
          <p style={{ color: 'white' }}>
            <ul>
              <ll>
                4.1) The Customer acknowledges and accepts that Blindata may suspend and/or
                interrupt the Service to ensure the maintenance interventions that are appropriate
                and/or necessary both to the premises that host the Infrastructure and to the
                servers and/or equipment contained therein. In such cases, Blindata undertakes to
                restore the Service as soon as possible according to the circumstances.
              </ll>
              <br></br> <br></br>
              <ll>
                4.2) Blindata also reserves the right to suspend and/or interrupt the provision of
                the Service in the following cases:
                <ll>
                  <br></br> <br></br>
                  <ul>
                    <ll>
                      - Improper use or violations of the Platform or Service by the Customer;
                    </ll>
                    <br></br> <br></br>
                    <ll>
                      - Failures and/or malfunctions to the network and devices for providing the
                      Service dependent on force majeure or that pose a danger to the internet
                      network, and/or non-programmable and/or foreseeable maintenance that is
                      technically essential;
                    </ll>
                    <br></br> <br></br>
                    <ll> - If there are justified reasons of security and/or confidentiality; </ll>
                    <br></br> <br></br>
                    <ll>
                      - Incorrect or non-compliant use of the Service by the Customer or failure by
                      the Customer to comply with legal obligations regarding the use of the
                      Service;
                    </ll>
                    <br></br> <br></br>
                    <ll>
                      - Service problems that are not remediable without suspending or interrupting
                      it.
                    </ll>
                    <br></br> <br></br>
                  </ul>
                </ll>
                <ll>
                  4.3) In case the interruption of the Service is planable with such advance notice
                  and the expected interruption exceeds 4 (four) hours, Blindata will give a notice
                  of at least 24 (twenty-four) hours, unless the interruption is placed in night or
                  holiday time and is due to maintenance needs.
                </ll>
                <ll>
                  4.4) In case of loss or theft of Credentials, Blindata reserves the right to
                  interrupt the Service. In addition, the Customer must immediately notify Blindata
                  by registered mail with return receipt or by certified e-mail, anticipated by
                  e-mail, attaching the complaint made to the competent authority.
                </ll>
              </ll>
            </ul>
          </p>
          <h2 style={{ color: 'white' }}>5 Service level</h2>
          <p style={{ color: 'white' }}>
            <ul>
              <ll>
                5.1) Blindata and the Customer mutually acknowledge that access to the Service will
                be provided by Blindata with a guarantee of a level of availability of the same
                equal to 90% (ninety percent) of the time, 24 hours a day, 7 days a week, for all
                year (<b>“Service Standard”</b> or <b>“SLA”</b>). In case of impossibility of
                accessing the Service lower than the SLA, which is directly attributable to
                Blindata, the same will respond within the limits of this article 6, expressly
                excluding now for then, any other compensation or reimbursement to the Customer for
                direct or indirect damages of any nature and kind. Compliance with the SLA will be
                calculated on an annual basis. Expressly excluded are interruptions of the Service
                or unavailability due to technical interventions or scheduled maintenance by
                Blindata, or its successors.
              </ll>
              <br></br> <br></br>
              <ll>
                5.2) In case of faults or malfunctions, the Customer undertakes to provide all the
                specifications and information possibly requested by Blindata, through the
                assistance service at the email address assistenza@blindata.io. Any irregularities
                or malfunctions of the Service must be communicated to Blindata within 24
                (twenty-four) hours from the date on which they came to the knowledge of the
                Customer, under penalty of forfeiture. Blindata, upon receipt of the communication,
                will activate in the shortest possible time to take charge of the Customer’s report,
                considering that interventions may be carried out from Monday to Friday, from 9.00
                am to 6.00 pm, excluding holidays. Any damage caused by a untimely communication by
                the Customer will not be attributable to Blindata. In no case any dispute and/or
                exception will give the Customer the right to suspend, delay or not make payment of
                the Fee.
              </ll>
              <br></br> <br></br>
              <ll>
                5.3) Blindata does not make any express or implied statements and warranties in
                relation to the satisfaction of the Customer’s needs by the Service or absence of
                errors in it.
              </ll>
              <br></br> <br></br>
              <ll>
                5.4) The Customer accepts and acknowledges that, in no case, Blindata can be held
                liable for damages, of any kind, that the Customer himself may suffer from delays,
                suspensions, interruptions, defects or malfunctions of the Platform and/or Service,
                whether they derive from any cause not attributable or attributable to Blindata,
                including in an entirely exemplary way the following:
                <ll>
                  <br></br> <br></br>
                  <ul>
                    <li>
                      Malfunctions of the Platform and/or Service caused, in general, by: (i)
                      negligent, culpable or fraudulent behavior of the Customer; (ii) failures
                      and/or malfunctions of equipment, systems and/or systems, of any type, in use
                      at the Customer’s premises;
                    </li>
                    <br></br>
                    <br></br>
                    <li>
                      Suspension and/or interruption of internet connectivity by the operator;
                    </li>
                    <br></br> <br></br>
                    <li>
                      Failures in the network and/or servers due to fortuitous events or force
                      majeure;
                    </li>
                    <br></br> <br></br>
                    <li>
                      - Unavailability of the Service and/or Platform for proven events systematic
                      not controllable by Blindata.
                    </li>
                    <br></br>
                    <br></br>
                  </ul>
                </ll>
              </ll>
              <ll>
                5.5) Any complaints about the provision of the Service must be addressed by the
                Customer to Blindata at the email address referred to in art. 5.2 above. Blindata
                will examine the complaint and provide a written response within 10 (ten) days from
                receipt of the same, unless otherwise agreed SLA.
              </ll>
              <br></br>
              <br></br>
              <ll>
                5.6) Starting from the activation of the Service, the Customer is considered the
                sole and exclusive responsible for the use of the Service, and consequently for all
                the data and content entered, present, transited and/or stored on the Platform. The
                Customer undertakes to indemnify and hold harmless Blindata from any cost, burden
                expense or damage that may be caused to it as a result of actions by third parties,
                including public authorities, resulting from violation of the commitments under this
                article.
              </ll>
              <br></br>
              <br></br>
              <ll>
                5.7) In case of damage resulting from fact or fault of Blindata, the liability of
                the latter can never exceed the maximum amount equal to the Fee paid in the last 3
                (three) months by the Customer.
              </ll>
            </ul>
          </p>
          <h2 style={{ color: 'white' }}>6 Intellectual property</h2>
          <p style={{ color: 'white' }}>
            <ul>
              <ll>
                6.1) All rights on trademarks, logos, names, and other distinctive signs associated
                with the Service, and the Platform, including the source codes, any developments and
                improvements made by Blindata, the related documentation, and any material made
                available to the Customer through the Service as well as all rights of economic use
                on them, are the exclusive property of Blindata.
              </ll>
              <br></br> <br></br>
              <ll>
                6.2) This Agreement does not grant the Customer any rights to Blindata’s trademarks
                and vice versa.
              </ll>
              <br></br> <br></br>
              <ll>
                6.3) The Platform constitutes the result, the set of technical knowledge and
                experience necessary for the realization, operation, setting, implementation,
                development and maintenance of the Service (<b>“Know How”</b>). Therefore, the
                Customer expressly undertakes not to disclose and disseminate to third parties the
                technical information received in execution of this Agreement and relating to the
                Know How as defined above, which are intended to remain secret also pursuant to art.
                98 of italian Legislative Decree no. 30 of 2005 (Code of industrial and intellectual
                property).
              </ll>
            </ul>
          </p>
          <h2 style={{ color: 'white' }}>7 Activation and renewal of the contract</h2>
          <p style={{ color: 'white' }}>
            <ul>
              <ll>
                7.1) Regardless of the date of signing the Order, this Agreement will enter into
                force from the date of activation of the Service for a period equal to the duration
                of the subscription subscribed, as indicated in art. 5 of the Order.
              </ll>
              <br></br> <br></br>
              <ll>
                7.2) At the expiry, the Contract will be automatically renewed, unless terminated in
                writing 60 days before the relative expiry in accordance with article 13 below.
              </ll>
              <br></br> <br></br>
            </ul>
          </p>
          <h2 style={{ color: 'white' }}>8 Termination</h2>
          <p style={{ color: 'white' }}>
            <ul>
              <ll>
                8.1) If a Party fails to fulfill its obligations under this Agreement, the other
                Party will have the right to contest the breach, in writing and by registered letter
                with return receipt or PEC, inviting the defaulting Party to remedy it within a term
                not less than 15 (fifteen) days. After the expiry of this term, the interested Party
                may terminate this Agreement, sending a written communication to the defaulting
                Party by registered letter with return receipt or PEC.
              </ll>
              <br></br> <br></br>
              <ll>
                8.2) Blindata may terminate by right, ex art. 1456 of the Italian Civil Code, this
                Agreement in the following cases:
                <br></br> <br></br>
                <ul>
                  <li>Art. 3: Use of the Service and the Platform;</li> <br></br> <br></br>
                  <li>Art. 6: Intellectual property;</li> <br></br> <br></br>
                  <li>Art. 9: Confidentiality;</li> <br></br> <br></br>
                  <li>Art. 10: Processing of Personal Data</li> <br></br> <br></br>
                  <li>Art. 11: Assignment of the Contract and subcontracting</li>
                </ul>
              </ll>
              <br></br> <br></br>
              <ll>
                8.3) In case of termination of the Contract, compensation for damages is reserved.
              </ll>
              <br></br> <br></br>
            </ul>
          </p>
          <h2 style={{ color: 'white' }}>9 Confidentiality</h2>
          <p style={{ color: 'white' }}>
            <ul>
              <ll>
                9.1) Each Party undertakes to maintain and to ensure that its employees,
                consultants, collaborators and administrators maintain the utmost confidentiality in
                relation to the existence and content of this Agreement and to all sensitive
                information of a commercial and technical nature concerning the activity carried out
                by the other Party and of which it has become aware. Each Party also undertakes not
                to communicate such information to third parties and to use such information
                exclusively for the purpose of fulfilling their respective obligations arising from
                this Agreement. This confidentiality obligation will be valid and effective for 3
                (three) years following the termination, loss of effectiveness, declaration of
                ineffectiveness or invalidity of this Agreement, for any reason whatsoever.
              </ll>
              <br></br> <br></br>
            </ul>
          </p>
          <h2 style={{ color: 'white' }}>10 Processing of personal data</h2>
          <p style={{ color: 'white' }}>
            <ul>
              <ll>
                10.1) Pursuant to and for the purposes of italian Legislative Decree no. 196/2003
                “Code on the protection of personal data”, as amended by italian Legislative Decree
                no. 101/2018, and EU Regulation 2016/679 of 27 April 2016 (<b>“GDPR”</b>), the
                Parties are required to comply with all the obligations provided for by the
                applicable legislation, including the obligations regarding security measures as per
                the GDPR.
              </ll>
              <br></br>
              <br></br>
              <ll>
                10.2) Blindata is required to store and keep confidential the personal data that it
                will receive from the Customer, which may be processed exclusively for the purposes
                related to the fulfillment of this Agreement.
              </ll>
              <br></br> <br></br>
              <ll>
                10.3) The Customer is the data controller of the personal data of the users,
                determining the purposes and means of the processing.
              </ll>
            </ul>
          </p>
          <h2 style={{ color: 'white' }}>11 Assignment of the contract and subcontracting</h2>
          <p style={{ color: 'white' }}>
            <ul>
              <ll>
                11.1) The Customer may not assign or transfer to third parties this Agreement, as
                well as the rights, powers and obligations arising therefrom, under penalty of its
                termination, and compensation for any damages in favor of Blindata, unless prior
                written consent of the latter.
              </ll>
              <br></br>
              <br></br>
            </ul>
          </p>
          <h2 style={{ color: 'white' }}>12 General rules</h2>
          <p style={{ color: 'white' }}>
            <ul>
              <ll>
                12.1) By signing the Contract, the Parties do not intend to establish any
                association or joint venture, nor do they intend to establish an agency
                relationship.
              </ll>
              <br></br> <br></br>
              <ll>
                12.2) Any delay in exercising a right will not constitute a waiver of that right.
              </ll>
              <br></br> <br></br>
              <ll>
                12.3) Any modification to the Contract will be valid and binding only if resulting
                from written act and signed by an authorized representative of each Party.
              </ll>
              <br></br> <br></br>
              <ll>
                12.4) Each Party undertakes not to make, or authorize, public or private statements
                or communications in relation to the economic conditions and the special conditions
                contained in the Order without the prior written consent of the interested Party,
                unless such statement or communication has become necessary as a result of the
                request of an Authority.
              </ll>
              <br></br> <br></br>
              <ll>
                12.5) The possible invalidity or ineffectiveness of one or more provisions of the
                Contract will not entail the invalidity or ineffectiveness of the remaining
                provisions of the Contract.
              </ll>
              <br></br> <br></br>
              <ll>
                12.6) It is understood that any discrepancy between what is contained in the
                Contract and in the Order will be resolved by giving precedence to the provisions of
                the Order.
              </ll>
            </ul>
          </p>
          <h2 style={{ color: 'white' }}>13 Communications</h2>
          <p style={{ color: 'white' }}>
            <ul>
              <ll>
                13.1) All communications between the Parties concerning the Contract must take place
                in writing, by PEC or registered letter with return receipt to the addresses
                indicated in the Order.
              </ll>
              <br></br> <br></br>
            </ul>
          </p>
          <h2 style={{ color: 'white' }}>14 Applicable law, disputes and competent court</h2>
          <p style={{ color: 'white' }}>
            <ul>
              <ll>14.1) This Contract is governed, exclusively, by Italian law.</ll> <br></br>{' '}
              <br></br>
              <ll>
                14.2) Any dispute concerning the interpretation, conclusion, execution,
                implementation, termination of this Contract, is of exclusive competence of the
                Court of Milan.
              </ll>
              <br></br> <br></br>
            </ul>
          </p>
        </form>
      </Card>
    </>
  );
}

export default TermsAndConditionsPage;
