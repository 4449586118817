import { combineReducers } from 'redux';
import {
  FETCH_TEAM_POLICIES_LIST_BY_USER_UUID,
  FETCH_TEAM_POLICIES_LIST_BY_TEAM_CODE,
  CREATE_TEAM_POLICY,
  MODIFY_TEAM_POLICY,
  DELETE_TEAM_POLICY
} from '../../constants/ActionTypes';

import _ from 'lodash';

const TEAM_POLICY_INITIAL_STATE = {
  currentPageByUserUuid: {},
  currentPageByTeamCode: {}
};

const defPage = {
  content: [],
  number: 0,
  size: 20,
  totalPages: 0,
  totalElements: 0
};

const currentPageByUserUuid = (state = TEAM_POLICY_INITIAL_STATE.currentPageByUserUuid, action) => {
  switch (action.type) {
    case FETCH_TEAM_POLICIES_LIST_BY_USER_UUID:
      return {
        ...state,
        [action.payload.userUuid]: action.payload
      };
    case CREATE_TEAM_POLICY:
    case MODIFY_TEAM_POLICY:
      let teamPolicy = action.payload;
      let userUuid = action?.payload?.user?.uuid;
      let oldCurrentPage = state[userUuid] || defPage;
      return {
        ...state,
        [userUuid]: {
          ...oldCurrentPage,
          content: _(oldCurrentPage.content)
            .keyBy('uuid')
            .assign({ [action.payload.uuid]: teamPolicy })
            .map()
            .value()
        }
      };
    case DELETE_TEAM_POLICY:
      return {
        ...state,
        [action.payload.userUuid]: {
          ...state[action.payload.userUuid],
          content: (state[action.payload.userUuid] || defPage).content.filter(
            (data) => data.uuid !== action.payload.policyUuid
          )
        }
      };
    default:
      return state;
  }
};

const currentPageByTeamCode = (state = TEAM_POLICY_INITIAL_STATE.currentPageByTeamCode, action) => {
  switch (action.type) {
    case FETCH_TEAM_POLICIES_LIST_BY_TEAM_CODE:
      return {
        ...state,
        [action.payload.teamCode]: action.payload
      };
    case CREATE_TEAM_POLICY:
    case MODIFY_TEAM_POLICY:
      let teamPolicy = action.payload;
      let teamCode = action.payload.teamCode;
      let oldCurrentPage = state[teamCode] || defPage;
      return {
        ...state,
        [teamCode]: {
          ...oldCurrentPage,
          content: _(oldCurrentPage.content)
            .keyBy('uuid')
            .assign({ [action.payload.uuid]: teamPolicy })
            .map()
            .value()
        }
      };
    case DELETE_TEAM_POLICY:
      return {
        ...state,
        [action.payload.teamCode]: {
          ...state[action.payload.teamCode],
          content: (state[action.payload.teamCode] || defPage).content.filter(
            (data) => data.uuid !== action.payload.policyUuid
          )
        }
      };
    default:
      return state;
  }
};

export default combineReducers({ currentPageByTeamCode, currentPageByUserUuid });
