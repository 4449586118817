import _ from 'lodash';
import { showMessage, showProgress, hideProgress } from './BehaviourActions';
import { LOGOUT } from '../constants/ActionTypes';
import { selectCurrentTenant } from 'reducers/settings/tenants';

export const buildApiCallAction =
  ({ api, apiFunction, actionType, payload, onError, onSuccess, customHandleError }) =>
  (dispatch, getState) => {
    api.token = getState().authentication.access_token;
    api.selectedTenantUuid = selectCurrentTenant(getState())?.uuid;
    showProgress()(dispatch, getState);
    return apiFunction(api, getState)
      .then((data) => {
        let actualPayload = _.isFunction(payload) ? payload(data) : payload || data;
        dispatch({ type: actionType, payload: actualPayload });
        if (_.isFunction(onSuccess)) {
          onSuccess(dispatch, getState);
        }
        return data;
      })
      .catch((error) => {
        if (_.isFunction(onError)) {
          onError(dispatch, getState);
        }
        if (_.isFunction(customHandleError)) {
          //custom error handling
          customHandleError(error);
        } else if (error.response) {
          handleApiError(error, dispatch, getState);
        } else {
          console.error(error);
          showMessage(error.message)(dispatch, getState);
        }
      })
      .then((data) => {
        hideProgress()(dispatch, getState);
        return data;
      });
  };

export function handleApiError(error, dispatch, getState) {
  try {
    let code = error.response ? error.response.status : undefined;
    if (code === 401) {
      dispatch({ type: LOGOUT });
    }
    if (code === 500) {
      //do not disclose internal errors
      showMessage(error.message || 'Internal Error')(dispatch, getState);
      return;
    }

    var message = error.message;
    error.response
      .json()
      .then((data) => {
        showMessage(data.message || message)(dispatch, getState);
      })
      .catch(() => showMessage(message)(dispatch, getState));
  } catch (e) {
    showMessage(error.message || 'Error')(dispatch, getState);
  }
}

export function actionsBuilder({ resource, keyProperty, api, apiMapping }) {
  const actionTypes = {
    fetch: `FETCH_${resource}`,
    fetchPage: `FETCH_${resource}S_PAGE`,
    search: `SEARCH_${resource}S`,
    create: `CREATE_${resource}`,
    modify: `MODIFY_${resource}`,
    delete: `DELETE_${resource}`
  };
  return {
    fetch: (key) =>
      buildApiCallAction({
        api,
        apiFunction: (api) => apiMapping.fetch.call(api, key),
        actionType: actionTypes.fetch
      }),
    fetchPage: (params) =>
      buildApiCallAction({
        api,
        apiFunction: (api) => apiMapping.fetchPage.call(api, params),
        actionType: actionTypes.fetchPage
      }),
    search: (params) =>
      buildApiCallAction({
        api,
        apiFunction: (api) => apiMapping.search.call(api, params),
        actionType: actionTypes.search
      }),
    create: (body) =>
      buildApiCallAction({
        api,
        apiFunction: (api) => apiMapping.create.call(api, body),
        actionType: actionTypes.create
      }),
    modify: (body) =>
      buildApiCallAction({
        api,
        apiFunction: (api) => apiMapping.modify.call(api, body),
        actionType: actionTypes.modify
      }),
    delete: (key) =>
      buildApiCallAction({
        api,
        apiFunction: (api) => apiMapping.delete.call(api, key),
        actionType: actionTypes.delete,
        payload: { [keyProperty]: key }
      })
  };
}
