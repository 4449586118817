import crudReducers from './utils';
import { combineReducers } from 'redux';
import ResourceTypes from 'constants/ResourceTypes';
import { FETCH_LOGICAL_RELATIONS_BY_PREDICATE } from 'constants/ActionTypes';

const relationsByPredicate = (state = {}, action) => {
  switch (action.type) {
    case FETCH_LOGICAL_RELATIONS_BY_PREDICATE:
      return {
        ...state,
        [action?.payload?.predicateID]: {
          ...state[action?.payload?.predicateID],
          [action.payload?.id]: action?.payload
        }
      };
    default:
      return state;
  }
};

export default combineReducers({
  relationsByPredicate,
  ...crudReducers({ resource: ResourceTypes.LOGICAL_RELATION, keyProperty: 'uuid' })
});
