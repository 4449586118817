import ResourceTypes from 'constants/ResourceTypes';
import crudReducers from 'reducers/utils';
import filtersReducer from 'reducers/utils/filters';
import { combineReducers } from 'redux';
import _ from 'lodash';
import { FETCH_LOGICAL_NAMESPACES_PAGE, SEARCH_LOGICAL_NAMESPACE } from 'constants/ActionTypes';

const filtersDefault = { search: '' };

const namespaceByName = (state = {}, action) => {
  switch (action.type) {
    case FETCH_LOGICAL_NAMESPACES_PAGE:
    case SEARCH_LOGICAL_NAMESPACE:
      return {
        ...state,
        ..._.keyBy(action.payload.content, 'name')
      };
    default:
      return state;
  }
};

export default combineReducers({
  namespaceByName,
  filters: filtersReducer({ resource: ResourceTypes.LOGICAL_NAMESPACE, filtersDefault }),
  ...crudReducers({ resource: ResourceTypes.LOGICAL_NAMESPACE, keyProperty: 'uuid' })
});
