import { combineReducers } from 'redux';
import {
  ADD_GRAPH_UUID_FILTER,
  REMOVE_GRAPH_UUID_FILTER,
  ADD_GRAPH_PROPERTY_FILTER,
  REMOVE_GRAPH_PROPERTY_FILTER,
  CHANGE_GRAPH_FILTER_EDITOR,
  REQUEST_GRAPH,
  RECEIVE_GRAPH,
  CLEAR_GRAPH,
  GRAPH_NODE_EXPANSION,
  CHANGE_GRAPH_PERSPECTIVE_FILTER,
  OPEN_GRAPH_CONTEXTUAL_MENU,
  CLOSE_GRAPH_CONTEXTUAL_MENU,
  FETCH_GRAPH_NODE_DETAIL,
  CLEAR_GRAPH_FILTERS
} from '../constants/ActionTypes';

const initialState = {
  loaded: false,
  content: {}
};

function data(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_GRAPH:
      return {
        ...state,
        content: action.payload,
        loaded: true
      };
    case CLEAR_GRAPH:
      return initialState;
    default:
      return state;
  }
}

const defaultFilters = {
  uuidFilters: [],
  propertyFilters: [],
  logicalPerspective: true,
  businessPerspective: true,
  loginsPerspective: true,
  physicalPerspective: true,
  dataFlowsPerspective: false,
  dataFlowsDepth: 5,
  dataFlowsDepthHierarchy: false,
  submitted: false,
  includeLogicalRelations: false,
  logicalRelationsDepth: 1
};

function filters(state = defaultFilters, action) {
  switch (action.type) {
    case CLEAR_GRAPH_FILTERS:
      return defaultFilters;
    case CHANGE_GRAPH_PERSPECTIVE_FILTER:
      return { ...state, ...action.payload };
    case ADD_GRAPH_UUID_FILTER:
      return {
        ...state,
        uuidFilters: state.uuidFilters
          .filter(
            (f) =>
              f.object.uuid !== action.payload.object.uuid ||
              f.entityType !== action.payload.entityType
          )
          .concat([action.payload])
      };
    case REMOVE_GRAPH_UUID_FILTER:
      return {
        ...state,
        uuidFilters: state.uuidFilters.filter(
          (f) =>
            f.object.uuid !== action.payload.object.uuid ||
            f.entityType !== action.payload.entityType
        )
      };
    case ADD_GRAPH_PROPERTY_FILTER:
      return {
        ...state,
        propertyFilters: state.propertyFilters
          .filter(
            (f) =>
              f.object.uuid !== action.payload.object.uuid ||
              f.entityType !== action.payload.entityType
          )
          .concat([action.payload])
      };
    case REMOVE_GRAPH_PROPERTY_FILTER:
      return {
        ...state,
        propertyFilters: state.propertyFilters.filter(
          (f) =>
            f.object.uuid !== action.payload.object.uuid ||
            f.entityType !== action.payload.entityType
        )
      };
    case REQUEST_GRAPH:
      return {
        ...state,
        submitted: true
      };
    case CLEAR_GRAPH:
      return {
        ...state,
        submitted: false
      };
    default:
      return state;
  }
}

const defaultFilterEditorState = {
  entityType: 'DATA_CATEGORY',
  openResourceFilterEditor: false,
  openPropertyFilterEditor: false,
  textProperty: '',
  ENTITIES: [
    'DATA_CATEGORY',
    'LOGICAL_FIELD',
    'PROCESSING',
    'TASK',
    'DATA_ACTOR',
    'SYSTEM',
    'SYSTEM_LOGIN',
    'PHYSICAL_ENTITY',
    'PHYSICAL_FIELD'
  ]
};

function filterEditor(state = defaultFilterEditorState, action) {
  switch (action.type) {
    case CHANGE_GRAPH_FILTER_EDITOR:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
}

function expansions(state = { sequence: 0, deltas: [] }, action) {
  switch (action.type) {
    case GRAPH_NODE_EXPANSION:
      return {
        sequence: state.sequence + 1,
        deltas: [...state.deltas, action.payload]
      };
    case CLEAR_GRAPH:
      return { sequence: 0, deltas: [] };
    default:
      return state;
  }
}

const defaultContextualMenu = {
  open: false,
  node: null,
  edge: null,
  nodeDetail: null,
  pointer: { DOM: { x: 0, y: 0 } }
};

function contextualMenu(state = defaultContextualMenu, action) {
  switch (action.type) {
    case OPEN_GRAPH_CONTEXTUAL_MENU:
      return {
        ...state,
        open: true,
        pointer: action.payload.pointer,
        node: action.payload.node,
        edge: action.payload.edge
      };
    case CLOSE_GRAPH_CONTEXTUAL_MENU:
      return defaultContextualMenu;
    default:
      return state;
  }
}

function nodeDetails(state = {}, action) {
  switch (action.type) {
    case FETCH_GRAPH_NODE_DETAIL:
      return {
        ...state,
        [action.payload.id]: action.payload
      };
    default:
      return state;
  }
}
export default combineReducers({
  data,
  filters,
  filterEditor,
  expansions,
  contextualMenu,
  nodeDetails
});
