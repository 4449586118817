import { sendError } from 'actions/settings/ErrorLoggingActions';
import React from 'react';
import { connect } from 'react-redux';
import './ErrorBoundary.css';
import { Button } from '@mui/material';
import { clearCaches } from 'components/app_version/AppVersion';

const ERROR_IN_SESSION = 'ErrorBoundary.ERROR_IN_SESSION';
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      chunkError: false,
      onLine: window?.navigator?.onLine
    };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    if (error.name === 'ChunkLoadError' && !window?.navigator?.onLine) {
      this.setState({ chunkError: true, onLine: false });
    }
    // You can also log the error to an error reporting service
    if (!window.sessionStorage.getItem(ERROR_IN_SESSION) && !this.state.chunkError) {
      this.props.sendError({
        url: window.location.href,
        errorInfo: error.componentDetails
          ? `[${error.componentDetails}] ${error.message}`
          : error.message,
        uiVersion: document.querySelector('meta[name="react_app_version"]')?.content
      });
      window.sessionStorage.setItem(ERROR_IN_SESSION, true);
    }

    console.log(error, info);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div style={{ textAlign: 'center' }}>
          {this.state.chunkError && this.state.onLine && (
            <p>A new version of the application is available!</p>
          )}

          {this.state.chunkError && !this.state.onLine && (
            <p>Network issues: check your internet connection.</p>
          )}

          {!this.state.chunkError && <p>Something went wrong.</p>}

          <p>Please reload the page.</p>

          <Button variant='contained' color='primary' onClick={() => clearCaches(true)}>
            Reload
          </Button>

          {this.state.chunkError && this.state.onLine && (
            <i id='download-icon' className='fa fa-download'></i>
          )}
          {this.state.chunkError && !this.state.onLine && (
            <i id='download-icon' className='fa fa-plug'></i>
          )}
          {!this.state.chunkError && <i id='bug-icon' className='fa fa-bug'></i>}
        </div>
      );
    }
    return this.props.children;
  }
}

export default connect(null, { sendError })(ErrorBoundary);
