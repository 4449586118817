import filtersReducer from 'reducers/utils/filters';
import { combineReducers } from 'redux';
import crudReducers from 'reducers/utils';
import ResourceTypes from 'constants/ResourceTypes';
import {
  FETCH_PROFILING_METRIC_ANOMALYS_BADGE_NUMBER,
  FETCH_PROFILING_METRIC_ANOMALYS_OCCURRENCES
} from 'constants/ActionTypes';
import _ from 'lodash';

const filtersDefault = {
  search: ''
};

const metricAnomaliesBadgeNumber = (state = {}, action) => {
  switch (action.type) {
    case FETCH_PROFILING_METRIC_ANOMALYS_BADGE_NUMBER:
      return { ...state, [action.payload.profilingMonitorUuid]: action.payload.content };
    default:
      return state;
  }
};

function occurrences(state = [], action) {
  switch (action.type) {
    case FETCH_PROFILING_METRIC_ANOMALYS_OCCURRENCES:
      return (
        action?.payload?.rows?.map((row) => {
          return _.zipObject(action.payload?.columns, row);
        }) || []
      );
    default:
      return state;
  }
}

export default combineReducers({
  occurrences,
  metricAnomaliesBadgeNumber,
  filters: filtersReducer({ resource: ResourceTypes.PROFILING_METRIC_ANOMALY, filtersDefault }),
  ...crudReducers({ resource: ResourceTypes.PROFILING_METRIC_ANOMALY, keyProperty: 'sequenceId' })
});
