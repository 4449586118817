import React from 'react';

const withError =
  ({ componentDetails }) =>
  (WrappedComponent) => {
    return class extends React.Component {
      componentDidCatch(error, info) {
        error.componentDetails = error.componentDetails || componentDetails;
        throw error;
      }
      render() {
        return <WrappedComponent {...this.props} />;
      }
    };
  };

export default withError;
