import React, { useState } from 'react';
import { selectTenant } from 'actions/LoginActions';
import { Button } from '@mui/material';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Domain } from '@mui/icons-material';
import TenantSwitcherModal from './TenantSwitcherModal';

function TenantSwitcherButton({ associatedTenants, selectedTenant, sx }) {
  const [open, setOpen] = useState(false);
  const onClick = associatedTenants?.length > 1 ? () => setOpen(true) : undefined;
  return (
    <>
      <Button
        sx={{ textTransform: 'none', ...sx }}
        size='small'
        color='primary'
        onClick={onClick}
        startIcon={<Domain />}
        disabled={!onClick}>
        {selectedTenant?.name}
      </Button>
      <TenantSwitcherModal open={open} onClose={() => setOpen(false)} />
    </>
  );
}
const mapStateToProps = (state) => ({
  associatedTenants: _.get(state, 'settings.profile.associatedTenants'),
  selectedTenant: state.settings.tenants.selectedTenant
});

export default connect(mapStateToProps, { selectTenant })(TenantSwitcherButton);
