import React, { Suspense } from 'react';

import SideBar from 'react-sidebar';

import Spinner from 'components/spinner/Spinner';
import MaterialTitlePanel from 'pages/sidebar/MaterialTitlePanel';
import SideBarMenu from 'pages/sidebar/SideBarMenu';

import './AppWithSideBar.css';

const mql = window.matchMedia(`(min-width: 1000px)`);
if (JSON.parse(localStorage.getItem('sidebar-docked')) === null) {
  localStorage.setItem('sidebar-docked', JSON.stringify('false'));
}
export default class AppWithSideBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mql: mql,
      docked: JSON.parse(localStorage.getItem('sidebar-docked')) || false,
      open: JSON.parse(localStorage.getItem('sidebar-docked')) || false
    };

    this.mediaQueryChanged = this.mediaQueryChanged.bind(this);
    this.toggleOpen = this.toggleOpen.bind(this);
    this.onSetOpen = this.onSetOpen.bind(this);
  }

  componentDidMount() {
    mql.addListener(this.mediaQueryChanged);
    this.setState({
      mql: mql,
      docked: mql.matches && JSON.parse(localStorage.getItem('sidebar-docked'))
    });
  }

  componentWillUnmount() {
    this.state.mql.removeListener(this.mediaQueryChanged);
  }

  onSetOpen(open) {
    this.setState({ open: open });
  }

  mediaQueryChanged() {
    this.setState({
      mql: mql,
      docked: this.state.mql.matches && JSON.parse(localStorage.getItem('sidebar-docked')),
      open: this.state.mql.matches && JSON.parse(localStorage.getItem('sidebar-docked'))
    });
  }

  toggleOpen(ev) {
    this.setState({ open: !this.state.open });

    if (ev) {
      ev.preventDefault();
    }
  }

  onItemSelected() {
    if (!this.state.docked) {
      this.toggleOpen();
    }
  }

  render() {
    const sideBarProps = {
      sidebar: (
        <Suspense fallback={<></>}>
          <SideBarMenu
            docked={this.state.docked}
            onItemSelected={() => this.onItemSelected()}
            onDockChange={() => {
              localStorage.setItem('sidebar-docked', JSON.stringify(!this.state.docked));
              this.setState({
                docked: !this.state.docked,
                open: !this.state.docked
              });
            }}
          />
        </Suspense>
      ),
      docked: this.state.docked,
      open: this.state.open,
      onSetOpen: this.onSetOpen,
      sidebarClassName: 'sidebar',
      styles: {
        root: {
          backgroundColor: '#F1F1F1'
        }
      }
    };

    return (
      <SideBar {...sideBarProps}>
        <Suspense fallback={<Spinner show />}>
          <MaterialTitlePanel
            toggleOpen={() => this.toggleOpen()}
            sideBarDocked={this.state.docked}
            sideBarOpen={this.state.open}>
            <div className='main-content'>{this.props.mainContent}</div>
          </MaterialTitlePanel>
        </Suspense>
      </SideBar>
    );
  }
}
