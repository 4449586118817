import { combineReducers } from 'redux';
import crudReducers from 'reducers/utils';
import filtersReducer from 'reducers/utils/filters';
import ResourceTypes from 'constants/ResourceTypes';

const filtersDefault = {
  search: ''
};

export default combineReducers({
  ...crudReducers({ resource: ResourceTypes.QUERY_BUCKET, keyProperty: 'uuid' }),
  filters: filtersReducer({ resource: ResourceTypes.QUERY_BUCKET, filtersDefault })
});
