import {
  FETCH_GOVERNANCE_POLICY_IMPLEMENTATIONS_PAGE_BY_POLICY,
  DELETE_GOVERNANCE_POLICY_IMPLEMENTATION,
  CREATE_GOVERNANCE_POLICY_IMPLEMENTATION,
  MODIFY_GOVERNANCE_POLICY_IMPLEMENTATION
} from 'constants/ActionTypes';
import ResourceTypes from 'constants/ResourceTypes';
import crudReducers from 'reducers/utils';
import { combineReducers } from 'redux';

const implementationsByPolicy = (state = {}, action) => {
  switch (action.type) {
    case FETCH_GOVERNANCE_POLICY_IMPLEMENTATIONS_PAGE_BY_POLICY:
      return action?.payload?.policyUuid
        ? { ...state, [action.payload.policyUuid]: action.payload }
        : state;
    case CREATE_GOVERNANCE_POLICY_IMPLEMENTATION:
      return {
        ...state,
        [action.payload.governancePolicy.uuid]: {
          ...state[action.payload.governancePolicy.uuid],
          content: (state[action.payload.governancePolicy.uuid] || []).content.concat([
            action.payload
          ])
        }
      };
    case MODIFY_GOVERNANCE_POLICY_IMPLEMENTATION:
      if (action.payload.governancePolicy.uuid && state[action.payload.governancePolicy.uuid]) {
        return {
          ...state,
          [action.payload.governancePolicy.uuid]: {
            ...state[action.payload.governancePolicy.uuid],
            content: state[action.payload.governancePolicy.uuid].content.map((impl) =>
              impl.uuid === action.payload.uuid ? action.payload : impl
            )
          }
        };
      }
      return state;
    case DELETE_GOVERNANCE_POLICY_IMPLEMENTATION:
      if (action.payload.governancePolicy.uuid && state[action.payload.governancePolicy.uuid]) {
        return {
          ...state,
          [action.payload.governancePolicy.uuid]: {
            ...state[action.payload.governancePolicy.uuid],
            content: (state[action.payload.governancePolicy.uuid].content || []).filter(
              (e) => e.uuid !== action.payload.uuid
            )
          }
        };
      }
      return state;
    default:
      return state;
  }
};

export default combineReducers({
  implementationsByPolicy,
  ...crudReducers({
    resource: ResourceTypes.GOVERNANCE_POLICY_IMPLEMENTATION,
    keyProperty: 'uuid'
  })
});
