import { combineReducers } from 'redux';
import {
  FETCH_ACL_STEWARDSHIP_RESPONSIBILITIES,
  FETCH_STEWARDSHIP_RESPONSIBILITYS_PAGE_BY_RESOURCE_TYPE,
  FETCH_STEWARDSHIP_RESPONSIBILITIES_BY_RESOURCE
} from '../../constants/ActionTypes';
import crudReducers from '../utils';

function acl(state = {}, action) {
  if (action.type === FETCH_ACL_STEWARDSHIP_RESPONSIBILITIES) {
    return {
      ...state,
      [`${action.payload.resourceType}$${action.payload.resourceIdentifier}`]:
        action.payload.content.length > 0
    };
  } else {
    return state;
  }
}

function contentByResource(state = {}, action) {
  if (action.type === FETCH_STEWARDSHIP_RESPONSIBILITIES_BY_RESOURCE) {
    return {
      ...state,
      [`${action.payload.resourceType}$${action.payload.resourceIdentifier}`]:
        action.payload.content
    };
  } else {
    return state;
  }
}

function responsibilitiesByResourceType(state = {}, action) {
  if (action.type === FETCH_STEWARDSHIP_RESPONSIBILITYS_PAGE_BY_RESOURCE_TYPE) {
    return {
      ...state,
      [`${action.payload.resourceType}`]: action.payload
    };
  } else {
    return state;
  }
}

export const extractStewardshipAcl =
  ({ resourceType, resourceIdentifier }) =>
  (state) =>
    state.stewardship.responsibilities.acl[`${resourceType}$${resourceIdentifier}`];

export const selectResponsibilitiesByResource =
  ({ resourceType, resourceIdentifier }) =>
  (state) =>
    state.stewardship.responsibilities.contentByResource[`${resourceType}$${resourceIdentifier}`];

export default combineReducers({
  ...crudReducers({ resource: 'STEWARDSHIP_RESPONSIBILITY', keyProperty: 'sequenceId' }),
  acl,
  contentByResource,
  responsibilitiesByResourceType
});
