import React from 'react';
import '../login/LoginPage.css';
import { Link } from 'react-router-dom';
import { Card, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

function PrivacyPolicyPage() {
  return (
    <>
      <IconButton
        style={{ position: 'absolute', top: '10px', left: '10px' }}
        component={Link}
        to='/login'
        size='large'>
        <ArrowBackIcon></ArrowBackIcon>
      </IconButton>
      <Card
        style={{
          position: 'absolute',
          top: '10%',
          left: '5%',
          width: '90%',
          height: '80%',
          padding: '30px',
          overflow: 'auto'
        }}>
        <form>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            <h1>Privacy Policy</h1>
          </div>
          <h2 style={{ color: 'White' }}>About data collection</h2>
          <p style={{ color: 'White' }}>
            Blindata S.r.l. with registered office in Monza, Via Angelo Mauri n. 22 (hereinafter
            Blindata) - as Data Controller - informs that some personal data of the users of the
            SaaS platform provided through the website https://app.blindata.io (the Service) will be
            processed in order to allow the provision of the services available through the Service.
            The personal data of the users of the Service will in particular consist of some data
            provided directly by them during registration (i.e. personal data such as name, surname,
            e-mail address), and data collected during the use of the Service (i.e. use of the
            platform features, IP address, ...).
          </p>
          <h2 style={{ color: 'White' }}>
            Purposes, legal bases of processing and data retention period.
          </h2>
          <p style={{ color: 'White' }}>
            The Personal Data will be processed for the following purposes:{' '}
            <ul>
              <ll>
                a) to provide access to the platform and deliver the Service. Legal basis:
                performance of a contract to which the data subject is party or in order to take
                steps at the request of the data subject prior to entering into a contract.
                Retention period: 10 years from the end of the contractual relationship
              </ll>
              <br></br>
              <br></br>
              <ll>
                b) to allow Blindata to carry out its internal administration activities, and to
                comply with any applicable regulation and/or order of public authorities. Legal
                basis: Necessity to fulfil legal obligations. Retention period: 10 years from the
                end of the contractual relationship
              </ll>
              <br></br>
              <br></br>
              <ll>
                c) to protect and enforce the rights and property of Blindata and intervene for the
                protection of the Service. Legal basis: Legitimate interest. Retention period: 10
                years from the end of the contractual relationship
              </ll>
            </ul>
          </p>
          <h2 style={{ color: 'White' }}>Communication of Personal Data.</h2>
          <p style={{ color: 'White' }}>
            For the purposes of managing the Services indicated above, Blindata may communicate the
            Personal Data to its consultants and lawyers, as well as to third parties in charge of
            specific processing. These subjects will have access only to the data necessary to
            fulfil their obligations and perform their functions (and will not be authorized to use
            them for other purposes), they will have to comply with Blindata’s instructions on the
            processing of Personal Data, they will act as data processors of Blindata’s Personal
            Data, and they will be listed in an updated list available at Blindata. The Personal
            Data will not otherwise be subject to communication or dissemination.
          </p>
          <h2 style={{ color: 'White' }}>Security measures.</h2>
          <p style={{ color: 'White' }}>
            Blindata has adopted security measures in order to minimize the risks related to both
            the loss or damage, even accidental, of the Personal Data, and the unauthorized access
            to the Personal Data or their unlawful processing.
          </p>
          <h2 style={{ color: 'White' }}>Sign in with Google.</h2>
          <p style={{ color: 'White' }}>
            When you access using Google to connect to our platforms or services, you agree to share
            your e-mail and profile picture with us. We will also record the fact that the
            registration took place through Google. The Google platform may receive information such
            as IP address and browser URL. For more information on your rights and ways to protect
            your privacy, please see Google’s privacy policy:
            https://policies.google.com/privacy?hl=en
          </p>
          <h2 style={{ color: 'White' }}>Sign in with Microsoft.</h2>
          <p style={{ color: 'White' }}>
            When you access using Microsoft to connect to our platforms or services, you agree to
            share your e-mail and profile picture with us. We will also record the fact that the
            registration took place through Microsoft. The Microsoft identity platform may receive
            information such as IP address and browser URL. For more information on your rights and
            ways to protect your privacy, please see Microsoft privacy policy:
            https://privacy.microsoft.com/
          </p>
          <h2 style={{ color: 'White' }}>Third Party Websites.</h2>
          <p style={{ color: 'White' }}>
            This Privacy Policy applies only to information collected through the Service. The
            Service may contain direct links to other websites. Blindata is not responsible for the
            privacy practices or content of these other websites.
          </p>
          <h2 style={{ color: 'White' }}>
            Exercise of the right granted by the data protection law.
          </h2>
          <p style={{ color: 'White' }}>
            At any time, the user may exercise the rights granted by law, i.e. the right to be
            informed about the existence, content, source, purposes and methods of processing of
            Personal Data, and the right to request updates, corrections, additions and (when
            permitted by law) deletion of Personal Data. These rights may be exercised by contacting
            the Data Controller at the e-mail address privacy@blindata.io. The user’s failure to
            consent to provide Personal Data may prevent Blindata from providing the Services. For
            any request or communication regarding this Privacy Policy or Personal Data for which
            processing has been authorized, please send a communication to the e-mail address
            privacy@blindata.io.
          </p>
        </form>
      </Card>
    </>
  );
}

export default PrivacyPolicyPage;
