import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import RaisedButton from '@mui/material/Button';
import { useDispatch } from 'react-redux';
import { login } from '../../../actions/LoginActions';
import { useHistory, useLocation } from 'react-router-dom';

export const blindataLoginButtonStyle = {
  margin: 4,
  width: 214
};

function BlindataSignInButton({ username, setting }) {
  let dispatch = useDispatch();
  let [password, setPassword] = useState(undefined);
  let history = useHistory();
  let location = useLocation();

  function handleClick(event) {
    event.preventDefault();
    dispatch(login(username, password)).then(() => {
      history.push(location.state?.from || '/');
    });
  }
  if (!setting?.disabled) {
    return (
      <>
        <TextField
          variant='standard'
          id='username-textfield'
          placeholder=''
          label='Username'
          InputLabelProps={{
            shrink: true
          }}
          value={username}
          margin='normal'
        />
        <br />
        <TextField
          variant='standard'
          id='password-textfield'
          type='password'
          placeholder=''
          InputLabelProps={{
            shrink: true
          }}
          label='Password'
          onChange={(event) => setPassword(event.target.value)}
          margin='normal'
        />
        <br />
        <RaisedButton
          id='login-button'
          variant='contained'
          color='primary'
          children='Sign in'
          style={blindataLoginButtonStyle}
          onClick={(event) => handleClick(event)}
        />

        <input type='submit' hidden onClick={(event) => handleClick(event)} />
      </>
    );
  }
}

export default BlindataSignInButton;
