import queryString from 'query-string';

export const handleErrors = (response) => {
  if (!response.ok) {
    console.error('Api error', response);
    let error = new Error(response.statusText);
    error.response = response;
    throw error;
  }
  return response;
};

export const parseJson = (response) => response.json().catch((err) => null);
export const fetchAll = (apiFunWithPageParam) => {
  return _fetchAllAux(apiFunWithPageParam, 0, 0, []);
};

const _fetchAllAux = (api, page, counter, acc) => {
  if (counter > 100) {
    throw new Error('Too many elements');
  }
  return api(page).then((data) => {
    if (data.last) {
      return {
        ...data,
        content: acc.concat(data.content)
      };
    } else {
      return _fetchAllAux(api, ++page, ++counter, acc.concat(data.content));
    }
  });
};

/*
 * Returns an encoded query string.
 */
export const encodeQueryData = (queryParamsMap = {}) => {
  return queryString.stringify(queryParamsMap, { skipNull: true });
};

export class BlindataApi {
  token = '';
  selectedTenantUuid = '';

  get(url, params) {
    return fetch(`${url}?${encodeQueryData(params)}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        ...(this.token ? { Authorization: `Bearer ${this.token}` } : {}),
        ...(this.selectedTenantUuid ? { 'X-BD-Tenant': this.selectedTenantUuid || '' } : {})
      }
    })
      .then(handleErrors)
      .then(parseJson);
  }

  delete(url) {
    return fetch(url, {
      method: 'DELETE',
      headers: {
        ...(this.token ? { Authorization: `Bearer ${this.token}` } : {}),
        ...(this.selectedTenantUuid ? { 'X-BD-Tenant': this.selectedTenantUuid || '' } : {})
      }
    }).then(handleErrors);
  }

  put(url, body) {
    return fetch(url, {
      method: 'PUT',
      headers: {
        ...(this.token ? { Authorization: `Bearer ${this.token}` } : {}),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...(this.selectedTenantUuid ? { 'X-BD-Tenant': this.selectedTenantUuid || '' } : {})
      },
      body: JSON.stringify(body)
    })
      .then(handleErrors)
      .then(parseJson);
  }

  post(url, body) {
    return fetch(url, {
      method: 'POST',
      headers: {
        ...(this.token ? { Authorization: `Bearer ${this.token}` } : {}),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...(this.selectedTenantUuid ? { 'X-BD-Tenant': this.selectedTenantUuid || '' } : {})
      },
      body: body && JSON.stringify(body)
    })
      .then(handleErrors)
      .then(parseJson);
  }

  postWithQueryParameters(url, body, params) {
    return this.post(`${url}?${encodeQueryData(params)}`, body);
  }

  patch(url, body) {
    return fetch(url, {
      method: 'PATCH',
      headers: {
        ...(this.token ? { Authorization: `Bearer ${this.token}` } : {}),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...(this.selectedTenantUuid ? { 'X-BD-Tenant': this.selectedTenantUuid || '' } : {})
      },
      body: JSON.stringify(body)
    })
      .then(handleErrors)
      .then(parseJson);
  }

  patchAll(url, body) {
    return fetch(url, {
      method: 'PATCH',
      headers: {
        ...(this.token ? { Authorization: `Bearer ${this.token}` } : {}),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...(this.selectedTenantUuid ? { 'X-BD-Tenant': this.selectedTenantUuid || '' } : {})
      },
      body: JSON.stringify(body)
    }).then(handleErrors);
  }

  download(url, params) {
    var f = document.createElement('form');
    f.method = 'POST';
    f.action = url;
    f.target = '_blank';
    var element1 = document.createElement('INPUT');

    //add access token for authentication
    element1.name = 'access_token';
    element1.value = this.token;
    element1.type = 'hidden';
    f.appendChild(element1);

    //add selected tenant param in form
    if (this.selectedTenantUuid) {
      var element2 = document.createElement('INPUT');
      element2.name = 'x_bd_tenant';
      element2.value = this.selectedTenantUuid;
      element2.type = 'hidden';
      f.appendChild(element2);
    }

    //set parameters in form
    for (let key in params) {
      if (params[key]) {
        if (Array.isArray(params[key])) {
          params[key].forEach((value) => {
            let e = document.createElement('INPUT');
            e.name = key;
            e.value = value;
            e.type = 'hidden';
            f.appendChild(e);
          });
        } else {
          let e = document.createElement('INPUT');
          e.name = key;
          e.value = params[key];
          e.type = 'hidden';
          f.appendChild(e);
        }
      }
    }

    //and dont forget to submit
    document.body.appendChild(f);
    f.submit();
    return new Promise((resolve) => resolve());
  }

  upload(url, data) {
    var formData = new FormData();
    for (let key in data) {
      formData.append(key, data[key]);
    }
    return fetch(url, {
      method: 'POST',
      headers: {
        ...(this.token ? { Authorization: `Bearer ${this.token}` } : {}),
        Accept: 'application/json',
        ...(this.selectedTenantUuid ? { 'X-BD-Tenant': this.selectedTenantUuid || '' } : {})
      },
      body: formData // This is your file object
    })
      .then(handleErrors)
      .then(parseJson);
  }
}
