import React, { useState } from 'react';
import { selectTenant } from 'actions/LoginActions';
import {
  ListItemText,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
  List,
  TextField,
  InputAdornment,
  ListItemButton
} from '@mui/material';
import { connect } from 'react-redux';
import _ from 'lodash';
import { useHistory, withRouter } from 'react-router';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';

function TenantSwitcherModal({ open, onClose, associatedTenants, selectTenant }) {
  const [search, setSearch] = useState(null);
  const [editor, setEditor] = useState(associatedTenants || null);
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <Dialog
      onClose={() => {
        setEditor(associatedTenants);
        setSearch(null);
        onClose();
      }}
      fullWidth
      open={open}>
      <DialogTitle children={'Change Tenant'} />
      <DialogContent sx={{ overflow: 'hidden', height: '280px' }}>
        <TextField
          autoComplete='off'
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <SearchIcon color='disabled' />
              </InputAdornment>
            )
          }}
          sx={{ marginTop: '2%' }}
          variant='outlined'
          fullWidth
          placeholder={'Search Tenant'}
          label={'Tenant'}
          value={search || ''}
          onChange={(event) => {
            setSearch(event.target.value);
            setEditor(
              associatedTenants.filter((ten) =>
                ten.name.toUpperCase().match(event.target.value.toUpperCase())
              )
            );
          }}
        />
        <List sx={{ overflow: 'auto', maxHeight: '200px', marginTop: '2%' }}>
          {_.sortBy(editor, 'name').map((tenant) => (
            <ListItemButton
              key={tenant.uuid}
              onClick={() => {
                onClose();
                selectTenant(tenant);
                setSearch(null);
                setEditor(associatedTenants);
                history.push('/');
              }}>
              <ListItemText primary={tenant.name} />
            </ListItemButton>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button
          children={t('commons.actions.cancel')}
          onClick={() => {
            setEditor(associatedTenants);
            setSearch(null);
            onClose();
          }}
        />
      </DialogActions>
    </Dialog>
  );
}

const mapStateToProps = (state) => ({
  associatedTenants: _.get(state, 'settings.profile.associatedTenants')
});

export default connect(mapStateToProps, { selectTenant })(withRouter(TenantSwitcherModal));
