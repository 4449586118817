import {
  List,
  ListItem,
  ListItemText,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Breadcrumbs,
  ListItemIcon,
  Grow
} from '@mui/material';
import React, { useRef, useEffect } from 'react';
import { useHistory } from 'react-router';
import _ from 'lodash';
import RecentIcon from '@mui/icons-material/History';
import NextIcon from '@mui/icons-material/NavigateNext';
import { EngagementCategoriesIcons } from 'constants/EngagementCategoriesIcons';
import { hoveredStyle, notHoveredStyle } from './HoverStyles';

export const RecentsList = ({ title, options, onItemClick, selectedItem, setSelectedItem }) => {
  const history = useHistory();
  const hoveredItem = useRef(null);

  useEffect(() => {
    hoveredItem.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'nearest'
    });
  }, [selectedItem]);

  return (
    <Grow in={true} mountOnEnter unmountOnExit {...{ timeout: 800 }}>
      <Card>
        <CardHeader title={title} avatar={<RecentIcon />} />
        <CardContent style={{ paddingTop: 0 }}>
          <List style={{ height: '300px', overflow: 'auto', paddingTop: 0 }}>
            {options.map((option) => {
              return (
                <ListItem
                  style={_.isEqual(option, selectedItem) ? hoveredStyle : notHoveredStyle}
                  ref={_.isEqual(option, selectedItem) ? hoveredItem : null}
                  key={option.sequenceId}
                  onClick={() => {
                    history.push(option.pagePath);
                    if (onItemClick) onItemClick();
                  }}
                  onMouseOver={() => setSelectedItem(option)}>
                  {EngagementCategoriesIcons[option.category] ? (
                    <ListItemIcon>{EngagementCategoriesIcons[option.category]}</ListItemIcon>
                  ) : (
                    <></>
                  )}
                  <ListItemText>
                    {option.pageTitle}
                    <Breadcrumbs separator={<NextIcon fontSize='small' />} aria-label='breadcrumb'>
                      {option.pagePath.split('/').map((element) => {
                        if (element === '') {
                          return null;
                        } else {
                          return <Typography key={element}>{element}</Typography>;
                        }
                      })}
                    </Breadcrumbs>
                  </ListItemText>
                </ListItem>
              );
            })}
          </List>
        </CardContent>
      </Card>
    </Grow>
  );
};
