import filtersReducer from 'reducers/utils/filters';
import { combineReducers } from 'redux';
import crudReducers from 'reducers/utils';
import ResourceTypes from 'constants/ResourceTypes';
import { FETCH_PROFILING_METRIC_INCIDENTS_OCCURRENCES } from 'constants/ActionTypes';
import _ from 'lodash';

const filtersDefault = {
  search: ''
};

function occurrences(state = [], action) {
  switch (action.type) {
    case FETCH_PROFILING_METRIC_INCIDENTS_OCCURRENCES:
      return (
        action?.payload?.rows?.map((row) => {
          return _.zipObject(action.payload?.columns, row);
        }) || []
      );
    default:
      return state;
  }
}

export default combineReducers({
  occurrences,
  filters: filtersReducer({ resource: ResourceTypes.PROFILING_METRIC_INCIDENT, filtersDefault }),
  ...crudReducers({ resource: ResourceTypes.PROFILING_METRIC_INCIDENT, keyProperty: 'sequenceId' })
});
