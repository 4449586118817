import { FETCH_GOVERNANCE_POLICY_EVALUATIONS_PAGE_BY_POLICY } from 'constants/ActionTypes';
import ResourceTypes from 'constants/ResourceTypes';
import crudReducers from 'reducers/utils';
import { combineReducers } from 'redux';
import _ from 'lodash';

const evaluationsByPolicy = (state = {}, action) => {
  switch (action.type) {
    case FETCH_GOVERNANCE_POLICY_EVALUATIONS_PAGE_BY_POLICY:
      return {
        ...state,
        [action.payload.policyUuid]: _.omit(action.payload, 'policyUuid')
      };
    default:
      return state;
  }
};

export default combineReducers({
  evaluationsByPolicy,
  ...crudReducers({
    resource: ResourceTypes.GOVERNANCE_POLICY_EVALUATION,
    keyProperty: 'sequenceId'
  })
});
