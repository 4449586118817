import React, { useState } from 'react';
import { ListItem, ListItemText } from '@mui/material';

import TenantSwitcherModal from './TenantSwitcherModal';
import { useSelector } from 'react-redux';

function TenantSwitcherListItem() {
  const [open, setOpen] = useState(false);
  const numOfAssociatedTenants = useSelector(
    (state) => state?.settings?.profile?.associatedTenants?.length
  );
  if (numOfAssociatedTenants < 2) {
    return null;
  }
  return (
    <>
      <ListItem button onClick={() => setOpen(true)}>
        <ListItemText inset primary='Tenants' />
      </ListItem>
      <TenantSwitcherModal open={open} onClose={() => setOpen(false)} />
    </>
  );
}

export default TenantSwitcherListItem;
